const audioPlayList = [
  {
    index: 0,
    title: "Foundation",
    audioTitle: "Take 6 Calm the Circuits",
    url: "/audio/Take-6-Calm-the-Circuits.mp3",
    desc: `This Innercise® leverages proven breathing techniques that effectively soothe the central nervous system, promoting a sense of <strong> calm, clarity, and control.</strong>`,
  },
  // {
  //   index: 1,
  //   title: "Expert",
  //   audioTitle: "Hypnosis for Weight Loss-with Steve-G. Jones",
  //   url: "/audio/Hypnosis-for-Weight-Loss-with-Steve-G.Jones.mp3",
  //   desc: "",
  // },
  {
    index: 2,
    title: "Business",
    audioTitle: "Entrepreneurial Success",
    url: "/audio/Entrepreneurial-Success.mp3",
    desc: "Get the proven, evidence-based mindset coaching to help you <strong>build and scale your business to new heights</strong> – from the founder of 5 multi-million dollar companies with over $5 billion in sales.",
  },
  {
    index: 3,
    title: "Wealth",
    audioTitle: "Financial Success",
    url: "/audio/Financial-Success-John.mp3",
    desc: "<strong>This Wealth Innercise® will help</strong> you accelerate your financial success so you can achieve your wealth goals faster and easier than ever before -- backed by proven science, 10 years of research, and over 100,000 members.",
  },
  {
    index: 4,
    title: "Relationship",
    audioTitle: "Self-Love",
    url: "/audio/Self-Love.mp3",
    desc: "In this Innercise®, you will work on <strong>cultivating self-love so you improve the relationship you have with yourself</strong> and the <strong>relationships you have with others.</strong>",
  },
  {
    index: 5,
    title: "Career",
    audioTitle: "Bursts of Career Affirmations",
    url: "/audio/Bursts-of-Career-Affirmations.mp3",
    desc: "This Innercise® will help you develop the <strong>confidence</strong> and <strong>certainty</strong> to take the inspired actions needed to advance your career and experience the fulfillment you deserve.",
  },
  {
    index: 6,
    title: "Mindset",
    audioTitle: "Mindset for Success",
    url: "/audio/Mindset-for-Success.mp3",
    desc: `This Innercise® is scientifically proven to help you master your mindset and improve your drive so you <strong>achieve ANY goal faster and easier than ever before</strong> -- backed by 10 years of cutting-edge brain research and over 100,000 members worldwide.`,
  },
  {
    index: 7,
    title: "Health",
    audioTitle: "(Health) Ultimate Health",
    url: "/audio/Ultimate-Health.mp3",
    desc: "This Ultimate Health & Wellness Innercise® will help you develop the mental fitness necessary to <strong>follow through on your health, weight loss, and/or wellness goals so you can get into the best shape of your life</strong> - without pills, potions, or fad diets.",
  },
]

export { audioPlayList }
