import React, { useEffect } from "react";
import Login from "components/common/Login";
import { useNavigate, Link } from "react-router-dom";
import styled from "@emotion/styled";
import { LoadUserInfo } from "utils/auth0";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 60px;
  align-items: center;
  padding: 0 0;
  color: white;
  justify-content: space-between;
  .logo {
    img {
      width: 200px;
    }
  }
`;

const Header = () => {
  let navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      LoadUserInfo(navigate);
    }, 0);
  }, []);

  return (
    <HeaderWrapper>
      <div className="logo" data-testid="logo">
        <Link to="/dashboard">
          <img src="ng-logo-b.svg" alt="nglogo" />
        </Link>
      </div>
      <Login />
    </HeaderWrapper>
  );
};

export default Header;
