import { httpGet, httpPost } from "utils/httpRequest";

const getCategories = async () => {
  const resp = await httpGet("client/category/list");
  return resp.data;
};

const addFocusCategory = async (focusedCategoryList) => {
  const resp = await httpPost("client/category/addFocusCategory", {
    focusedCategoryList,
  });
  return resp.data;
};

export { getCategories, addFocusCategory };
