import React, { useState, useEffect } from "react";
import BodyContentContainer from "components/common/LayoutComponents/BodyContentContainer";
import { getDailyPrime } from "services/prime";
import { verifyResponse } from "utils/httpRequest";
import {
  StyledDailyPrimeWrapper,
  StyledDailyPrimeContainer,
} from "./DailyPrime.style";

const DailyPrime = () => {
  const [loading, setLoading] = useState(false);
  const [prime, setPrime] = useState(null);

  const loadDailyPrime = async () => {
    setLoading(true);
    const response = await getDailyPrime();
    setLoading(false);
    verifyResponse(response);
    setPrime(response.data);
  };

  useEffect(() => {
    loadDailyPrime();
  }, []);

  const renderDailyPrimeContent = () => {
    return (
      <StyledDailyPrimeContainer>
        {prime ? (
          <img
            src={prime.thumbResourceDto.urlDto.imgUrl_MD}
            alt="daily prime"
          />
        ) : (
          <p>daily prime loading</p>
        )}
      </StyledDailyPrimeContainer>
    );
  };
  return (
    <StyledDailyPrimeWrapper>
      <BodyContentContainer>{renderDailyPrimeContent()}</BodyContentContainer>
    </StyledDailyPrimeWrapper>
  );
};

export default DailyPrime;
