import styled from "@emotion/styled"

const StyledPhoneAnimateC = styled.div`
  display: flex;
  position: relative;
  aspect-ratio: 724/838;
  max-width: 550px;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* background-color: antiquewhite; */
`

const StyledPhoneFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-image: url("/images/phoneC-frame.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  /* max-height: 500px; */
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 10;
`
const StyledPhoneAnimateContent = styled.div`
  position: absolute;
  width: 49.8%;
  top: 2.4%;
  left: 22.5%;
  display: flex;
  video {
    width: 100%;
  }
`

export { StyledPhoneAnimateC, StyledPhoneFrame, StyledPhoneAnimateContent }
