import styled from "styled-components"

const StyledQrCode = styled.div`
  display: flex;
  position: relative;
  border: 5px solid white;
  border-radius: 3px;
  width: 256px;
  height: 256px;
  justify-content: center;
  align-items: center;
`

export { StyledQrCode }
