import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "category",
  initialState: {
    selectedCategoryId: 0,
    categories: [],
  },

  reducers: {
    updateSelectCategoryId: (state, { payload }) => {
      return {
        ...state,
        selectedCategoryId: payload,
      };
    },
    updateCategories: (state, { payload }) => {
      return {
        ...state,
        categories: payload,
      };
    },
  },
});

export const { updateSelectCategoryId, updateCategories } =
  categorySlice.actions;

export default categorySlice.reducer;
