import React, { useEffect } from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"
import { LogFirebaseEvent } from "thirdParty/firebaseConfig"
import FullWidthContainer from "components/common/LayoutComponents/FullWidthContainer"
import Header from "./Header"
import Footer from "./Footer"
import Dashboard from "components/Pages/Dashboard"
import TermsAndConditions from "components/Pages/Terms/Terms"
import PrivacyPolicy from "components/Pages/PrivacyPolicy"
import LandingPage from "components/Pages/LandingPage"
import CategoryMenu from "components/common/CategoryMenu"
import AppShare from "components/Pages/AppShare/AppShare"
import LandingProgram from "components/Pages/LandingProgram"
import WaitingList from "components/Pages/WaitingList"
import WaitingListConfirmation from "components/Pages/WaitingListConfirmation"
import DownloadAppSharePage from "components/Pages/DownloadAppSharePage"
// import EmptyPage from "components/Pages/EmptyPage"
import Faq from "components/Pages/Faq/Faq"
import NurturePage from "components/Pages/NurturePage"
import EmailVerification from "components/Pages/EmailVerification/EmailVerification"
import ResetPasswordSuccess from "components/Pages/ResetPasswordSuccess"
const NotFound = React.lazy(() => import("components/Pages/NotFound"))
const Profile = React.lazy(() => import("components/Pages/Profile"))

const NormalMenuTemplate = (PageComponent) => {
  return (
    <>
      <FullWidthContainer>
        <Header />
      </FullWidthContainer>
      <PageComponent />
      <FullWidthContainer>
        <Footer />
      </FullWidthContainer>
    </>
  )
}

const NormalWithMenuTemplate = (PageComponent) => {
  return (
    <>
      <FullWidthContainer>
        <Header />
        <CategoryMenu />
      </FullWidthContainer>
      <PageComponent />
      <FullWidthContainer>
        <Footer />
      </FullWidthContainer>
    </>
  )
}

function RouteChangeTracker() {
  const location = useLocation()

  useEffect(() => {
    // Log a 'page_view' event to Firebase Analytics
    LogFirebaseEvent("page_view", {
      page_path: location.pathname,
      page_location: window.location.href,
      page_title:
        window.pageTitle === ""
          ? window.title
          : `${window.title} - ${window.pageTitle}`,
    })
    window.pageTitle = ""
  }, [location])

  return null
}

const WebRoutes = () => {
  return (
    <React.Suspense fallback={null}>
      <Router>
        <Routes>
          {/* <Route exact path="/" element={NormalMenuTemplate(Home)} /> */}
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/app" element={<AppShare />} />
          <Route exact path="/paywall" element={<AppShare />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/download" element={<LandingPage />} />
          <Route exact path="/waitlist" element={<WaitingList />} />
          <Route exact path="/waitlist/share" element={<WaitingList />} />

          <Route
            exact
            path="/waitlist/confirmation"
            element={<WaitingListConfirmation />}
          />
          <Route
            exact
            path="/download/confirmation"
            element={<DownloadAppSharePage />}
          />
          <Route
            exact
            path="/dashboard"
            element={NormalWithMenuTemplate(Dashboard)}
          />
          <Route
            exact
            path="/profile"
            element={NormalWithMenuTemplate(Profile)}
          />
          <Route
            exact
            path="/email-verification"
            element={<EmailVerification />}
          />
          <Route
            exact
            path="/reset-password-success"
            element={<ResetPasswordSuccess />}
          />

          {/* <Route
            exact
            path="/program/:id"
            element={NormalWithMenuTemplate(Program)}
          /> */}
          <Route
            exact
            path="/nurture-waitlist"
            element={<NurturePage target="waitlist" />}
          />
          <Route
            exact
            path="/nurture-download"
            element={<NurturePage target="download" />}
          />
          <Route exact path="/program" element={<LandingProgram />} />
          <Route exact path="/terms" element={<TermsAndConditions />} />
          <Route exact path="/policy" element={<PrivacyPolicy />} />
          {/* <Route
            exact
            path="/innercise/:id"
            element={NormalWithMenuTemplate(Innercise)}
          /> */}
          <Route exact path="/innercise" element={<LandingProgram />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <RouteChangeTracker />
      </Router>
    </React.Suspense>
  )
}

export default WebRoutes
