const experts = [
  { url: "/images/experts/1.png", alt: "" },
  { url: "/images/experts/2.png", alt: "" },
  { url: "/images/experts/3.png", alt: "" },
  { url: "/images/experts/4.png", alt: "" },
  { url: "/images/experts/5.png", alt: "" },
  { url: "/images/experts/6.png", alt: "" },
  { url: "/images/experts/7.png", alt: "" },
  { url: "/images/experts/8.png", alt: "" },
  { url: "/images/experts/9.png", alt: "" },
  { url: "/images/experts/10.png", alt: "" },
]

const testimonial = [
  { url: "/images/testimonial/1.png", alt: "" },
  { url: "/images/testimonial/2.png", alt: "" },
  { url: "/images/testimonial/3.png", alt: "" },
  { url: "/images/testimonial/4.png", alt: "" },
  { url: "/images/testimonial/5.png", alt: "" },
  { url: "/images/testimonial/6.png", alt: "" },
  { url: "/images/testimonial/7.png", alt: "" },
  { url: "/images/testimonial/8.png", alt: "" },
  { url: "/images/testimonial/9.png", alt: "" },
  { url: "/images/testimonial/10.png", alt: "" },
  { url: "/images/testimonial/11.png", alt: "" },
]

export { experts, testimonial }
