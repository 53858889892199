import styled from "@emotion/styled";

const StyledCategoryDisplay = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSubCategoryTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin: 10px 0;
`;

const StyledProgramContainer = styled.div`
  display: flex;
  padding: 20px 0 40px 0;
`;

const StyledProgramFrame = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  .program-name {
    font-weight: 500;
    font-size: 18px;
    margin: 8px 0;
}
  }
  img {
    width: 100%;
  }
`;

export {
  StyledCategoryDisplay,
  StyledSubCategoryTitle,
  StyledProgramContainer,
  StyledProgramFrame,
};
