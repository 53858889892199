import React, { useState, useEffect } from "react"
import PageTitle from "components/common/PageTitle/PageTitle"
import styled from "@emotion/styled"
import { getFaqList } from "services/faq"
import { verifyResponse } from "utils/httpRequest"

const FaqWrapper = styled.div`
  display: flex;
  /* background-color: rgba(131, 71, 173, 0.13); */
  width: 100%;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;

  .logo {
    margin: 20px 0;
    img {
      width: 100px;
    }
  }

  .heading {
    font-size: 28px;
    font-weight: bold;
    width: 100%;
    padding-bottom: 30px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1100px;
    width: calc(100% - 60px);
    min-height: 100vh;
    padding: 20px 30px;
    background-color: white;
    /* border: 1px solid gray; */

    .faq-section {
      padding: 20px 0;
      .question {
        font-weight: 500;
      }
      .answer {
        font-weight: 300;
      }
    }
  }
`

const Faq = () => {
  const [faqList, setFaqList] = useState([])

  const getFaqs = async () => {
    const response = await getFaqList()
    verifyResponse(response)
    setFaqList(response.data)
  }

  useEffect(() => {
    getFaqs()
  }, [])

  return (
    <FaqWrapper>
      <PageTitle title="FAQ Page" />
      {/* <div className="logo">
        <a href="/download">
          <img src="/images/i-logo.png" alt="logo" />
        </a>
      </div> */}

      <div className="content">
        <div className="heading">MyInnercise.com FAQ</div>
        {faqList.map((faq, index) => {
          return (
            <div className="faq-section">
              <div className="question">
                {`${index + 1}. `} {faq.title}
              </div>
              <div
                className="answer"
                dangerouslySetInnerHTML={{ __html: faq.answer }}
              />
            </div>
          )
        })}
      </div>
    </FaqWrapper>
  )
}

export default Faq
