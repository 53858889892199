import React from "react"
import {
  StyledPhoneAnimateC,
  StyledPhoneAnimateContent,
  StyledPhoneFrame,
} from "./PhoneAnimateC.style"

const PhoneAnimateC = () => {
  return (
    <StyledPhoneAnimateC className="phone-animation">
      <StyledPhoneFrame />
      <StyledPhoneAnimateContent>
        <video
          src="/media/iphoneC-animation.mp4"
          playsInline
          autoPlay
          muted
          loop
        ></video>
      </StyledPhoneAnimateContent>
    </StyledPhoneAnimateC>
  )
}

export default PhoneAnimateC
