import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { LogoutCallBack } from "utils/auth0";
import { LoginAction, LogoutAction, IsLogin, GetUserInfo } from "utils/auth0";
import Button from "@material-ui/core/Button";

const StyledLoginPenal = styled.div`
  display: flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  margin-right: 15px;
  a {
    color: #bfc7d6;
    text-underline-offset: 2px;
  }
  button {
    color: #eff0f2;
  }
`;

const StyledLoginPenalItem = styled.div`
  margin-right: 20px;
  display: inline-block;
`;

const StyledProfileImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;

const Login = () => {
  const userInfo = GetUserInfo();
  return IsLogin() ? (
    <StyledLoginPenal data-testid="login-penal">
      <StyledLoginPenalItem>
        Hi <Link to={"/profile"}>{userInfo.nickname}</Link>
      </StyledLoginPenalItem>
      <StyledLoginPenalItem>
        <Button
          variant="text"
          onClick={() => {
            // logout({ returnTo: window.location.origin });
            LogoutAction();
            LogoutCallBack();
          }}
        >
          SignOut
        </Button>
      </StyledLoginPenalItem>

      <StyledProfileImageWrapper>
        <Link to={"/profile"}>
          <img src={userInfo.picture} alt="profile pic" />
        </Link>
      </StyledProfileImageWrapper>
    </StyledLoginPenal>
  ) : (
    <StyledLoginPenal>
      <StyledLoginPenalItem>
        <Button
          variant="text"
          onClick={() => {
            LoginAction();
          }}
        >
          Login / SignUp
        </Button>
      </StyledLoginPenalItem>
    </StyledLoginPenal>
  );
};

export default Login;
