import React from "react"
import styled from "@emotion/styled"

const EmailVerificationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;

  .logo {
    margin: 20px 0;
    img {
      width: 200px;
    }
  }

  .sub-title {
    font-size: 17px;
    margin-top: -15px;
    font-weight: 500;
  }

  .content {
    margin-top: 80px;
    text-align: center;

    .congrats {
      font-size: 29px;
      font-weight: 500;
    }
    .msg {
      font-weight: 400;
      margin: 3px 0 0px 0px;
    }
  }
`

const EmailVerification = () => {
  return (
    <EmailVerificationWrapper>
      <div className="logo">
        <a href="/download">
          <img src="/images/i-logo.png" alt="logo" />
        </a>
      </div>
      <div className="sub-title">strengthen your mind™</div>
      <div className="content">
        <div className="congrats">congrats</div>
        <div className="msg">your email address is verified!</div>
      </div>
    </EmailVerificationWrapper>
  )
}

export default EmailVerification
