import { httpPost } from "utils/httpRequest";

const getClientRecommendation = async () => {
  const resp = await httpPost("client/program/listRecommendedPrograms", {
    pageNo: 1,
    pageSize: 999999,
  });
  return resp.data;
};

export { getClientRecommendation };
