import { useState, useEffect, useRef, useCallback } from "react";

const UseToggleDelay = (callbackAction, timeout) => {
  const countDownIntervalVal = 500;

  const [time, setTime] = useState(0);
  const timeRef = useRef(null);
  timeRef.current = time;

  const [intervalObj, setIntervalObj] = useState(null);
  const intervalObjRef = useRef(null);
  intervalObjRef.current = intervalObj;

  const outsideCallBack = useCallback(callbackAction, [callbackAction]);

  const countDown = useCallback(() => {
    setTime(timeRef.current - countDownIntervalVal);
  }, []);

  useEffect(() => {
    if (timeRef.current <= 0) {
      clearInterval(intervalObjRef.current);
      setIntervalObj(null);
      outsideCallBack();
    }
  }, [time]);

  const trigger = () => {
    if (!intervalObjRef.current) {
      setIntervalObj(setInterval(countDown, countDownIntervalVal));
      setTime(timeout);
    } else {
      setTime(timeout);
    }
  };

  return trigger;
};

export default UseToggleDelay;
