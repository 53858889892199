import auth0 from "auth0-js";
import { SESSION_VARIABLES } from "constants/system";

const LoginCallBack = async () => {
  // TODO
};

const LogoutCallBack = () => {
  // TODO
};

const LoginAction = () => {
  const myAuth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT,
  });

  myAuth0.authorize({
    audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
    scope: "openid email profile",
    responseType: "token id_token",
    connection: process.env.REACT_APP_AUTH0_DB,
    redirectUri: `${window.location.origin}`,
  });
};

const LogoutAction = () => {
  const myAuth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT,
  });

  myAuth0.logout({
    returnTo: window.location.origin,
    clientID: process.env.REACT_APP_AUTH0_CLIENT,
  });
  localStorage.clear();
};

const LoadUserInfo = async (navigate) => {
  const myAuth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT,
  });

  myAuth0.parseHash({ hash: window.location.hash }, async (err, authResult) => {
    if (err) {
      console.log(err);
      const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN);
      if (!idToken || idToken === "") {
        navigate("/");
      }
    }

    if (authResult) {
      localStorage.setItem(SESSION_VARIABLES.ACCESS_TOKEN, authResult.idToken);
      // store user info into local
      localStorage.setItem("nickname", authResult.idTokenPayload.nickname);
      localStorage.setItem("name", authResult.idTokenPayload.name);
      localStorage.setItem("email", authResult.idTokenPayload.email);
      localStorage.setItem("picture", authResult.idTokenPayload.picture);
      // redirect to home page
      navigate("/");
    } else {
      const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN);
      // redirect to home page if not login or loss session
      (!idToken || idToken === "") && navigate("/");
    }
  });
};

const IsLogin = () => {
  const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN);
  return idToken && idToken !== "";
};

const GetUserInfo = () => {
  return {
    atoken: localStorage.getItem("atoken"),
    email: localStorage.getItem("email"),
    name: localStorage.getItem("name"),
    nickname: localStorage.getItem("nickname"),
    picture: localStorage.getItem("picture"),
  };
};

export {
  LoginAction,
  LogoutAction,
  IsLogin,
  LoadUserInfo,
  GetUserInfo,
  LoginCallBack,
  LogoutCallBack,
};
