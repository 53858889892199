import React from "react"
import PageTitle from "components/common/PageTitle/PageTitle"
import VideoPlayer from "components/common/VideoPlayer/VideoPlayer"
import { ReactComponent as QuestionCircleLogo } from "icons/question-circle.svg"
import { useNavigate } from "react-router-dom"
import { NurturePageWrapper, PageContent } from "./NurturePage.style"

const NurturePage = ({ target }) => {
  const navigate = useNavigate()

  return (
    <NurturePageWrapper target={target}>
      <PageTitle
        title={
          target === "waitlist"
            ? "Nurture Waitlist Page"
            : "Nurture Download Page"
        }
      />
      <div className="video-section">
        <div className="logo">
          <img src="/images/Innercise_logo.png" alt="innercise logo" />
        </div>
        <PageContent className="player-section">
          <div className="video-content">
            {target === "waitlist" ? (
              <div className="title">
                CLEAR YOUR MIND
                <br className="mobile-only" /> OF “CAN’T”
              </div>
            ) : (
              <div className="title">
                A MINDSET COACH <br />
                IN THE PALM OF YOUR HAND
              </div>
            )}

            <VideoPlayer
              url={`/media/${
                target === "waitlist"
                  ? "intro-nurture-waitlist"
                  : "intro-nurture-download"
              }.mp4`}
              // thumbnail="/images/nurture-waitlist-cover.jpg"
              thumbnail={`/images/${
                target === "waitlist"
                  ? "nurture-waitlist-cover"
                  : "nurture-download-cover"
              }.jpg`}
            />
          </div>
        </PageContent>
      </div>

      <PageContent>
        <div className="desc-content">
          <button
            onClick={() => {
              target === "waitlist" ? navigate("/") : navigate("/download")
            }}
          >
            Join Now For Free
          </button>
        </div>
      </PageContent>
      <div className="footer">
        <PageContent className="content">
          <div className="help-contact">
            <div className="question-sign">
              <QuestionCircleLogo />
              <strong>Need Help?</strong>
            </div>
             support@email.myinnercise.com
          </div>
          <div className="links center">
            <a href="/">Contact Us</a> {" - "}
            <a href="/terms">Terms & Conditions of Sale</a>
          </div>
          <img src="/images/app-store.png" alt="apple store logo" />
        </PageContent>
      </div>
    </NurturePageWrapper>
  )
}

export default NurturePage
