import React from "react"
import QrCode from "components/common/QrCode/QrCode"
import { GlobalStyle } from "styles/global"
// import PageTitle from "components/common/PageTitle/PageTitle"
import styled from "@emotion/styled"

const AppShareWrapper = styled.div`
  display: flex;
  background-color: rgba(131, 71, 173, 0.13);
  width: 100%;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;

  .logo {
    margin: 20px 0;
    img {
      width: 100px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 550px;
    width: calc(100% - 40px);
    padding: 10px 20px;
    min-height: 500px;
    background-color: white;

    .welcome {
      font-family: Aeonik;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: ${GlobalStyle.colors.lightGreen};
      padding: 22px 0;
    }

    .heading {
      font-family: Aeonik;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      text-align: center;
      padding: 35px 0;
    }

    .sub-heading {
      font-family: Aeonik;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      max-width: 350px;
      text-align: center;
    }
    .share-qr-code {
      display: flex;
      justify-content: space-around;
      width: 100%;

      .share-section {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .qr-code {
      background: url(Screen Shot 2023-03-08 at 8.33.png);
      box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      width: 160px;
      height: 160px;
      margin: 40px 0;
      canvas {
        border: 1px solid #00000012;
        border-radius: 7px;
      }
    }

    .app-store {
      width: 95px;
    }

    .action-link {
      margin-top: 40px;
      font-size: 14px;
      color: #0500ff;
      padding: 2px 0;
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid #0500ff;
      background-color: transparent;
      cursor: pointer;
    }
  }

  @media (max-width: 585px) {
    .content {
      .share-qr-code {
        flex-direction: column;
      }
    }
  }
`

const AppShare = () => {
  return (
    <AppShareWrapper>
      <div className="logo">
        <a href="/download">
          <img src="/images/i-logo.png" alt="logo" />
        </a>
      </div>

      <div className="content">
        <div className="heading">Download The innercise® App</div>
        <div className="sub-heading">
          To download this app, just open your phone's camera app to scan this
          QR code
        </div>

        <div className="share-qr-code">
          <div className="share-section apple">
            <QrCode codeValue="https://apps.apple.com/us/app/innercise/id1616604572" />
            <a href="https://apps.apple.com/us/app/innercise/id1616604572">
              <img
                src="/images/app-store.png"
                alt="app store"
                className="app-store"
              />
            </a>
          </div>
          <div className="share-section google">
            <QrCode codeValue="https://play.google.com/store/apps/details?id=com.myneurogym.innercise&pli=1" />
            <a href="https://play.google.com/store/apps/details?id=com.myneurogym.innercise&pli=1">
              <img
                src="/images/google-play-store.png"
                alt="app store"
                className="app-store"
              />
            </a>
          </div>
        </div>
      </div>
    </AppShareWrapper>
  )
}

export default AppShare
