import React from "react"
import { ResetPasswordSuccessWrapper } from "./ResetPasswordSuccess.style"

const ResetPasswordSuccess = () => {
  return (
    <ResetPasswordSuccessWrapper>
      <div className="logo">
        <a href="/download">
          <img src="/images/i-logo.png" alt="logo" />
        </a>
      </div>
      <div className="sub-title">strengthen your mind™</div>
      <div className="content">
        <div className="title">Password reset successfully!</div>
        <div className="msg">
          Please log into the app using your new password. Enjoy Innercising!
        </div>
      </div>
    </ResetPasswordSuccessWrapper>
  )
}

export default ResetPasswordSuccess
