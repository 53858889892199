import styled from "@emotion/styled"

const StyledPhoneAnimateB = styled.div`
  display: flex;
  position: relative;
  aspect-ratio: 724/838;
  max-width: 550px;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const StyledPhoneFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-image: url("/images/phoneB-frame.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 10;
`
const StyledPhoneAnimateContent = styled.div`
  position: absolute;
  width: 50%;
  top: 2%;
  left: 25.9%;
  display: flex;
  video {
    width: 100%;
  }
`

export { StyledPhoneAnimateB, StyledPhoneFrame, StyledPhoneAnimateContent }
