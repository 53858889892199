import axios from "axios";

const httpGet = (url) => {
  const token = localStorage.getItem("atoken");

  const requestUrl = `${process.env.REACT_APP_API_HOST}/${url}`;
  return axios.get(requestUrl, {
    headers: {
      access_token: token,
      client_type: 2,
      client_version: 1.0,
      salt: Math.random(),
    },
  });
};

const httpPost = (url, data) => {
  const token = localStorage.getItem("atoken");
  const adminUserId = localStorage.getItem("adminUserId");
  const requestUrl = `${process.env.REACT_APP_API_HOST}/${url}`;

  // console.info("data: ", data);
  return axios.post(requestUrl, data, {
    headers: {
      access_token: token,
      user_id: adminUserId,
      client_type: 2,
      client_version: 1.0,
      salt: Math.random(),
    },
  });
};

const verifyResponse = (response) => {
  // console.log("response: ", response);
  // redirect to login page if the token invalided
  if (response === undefined) {
    localStorage.clear();
    window.location = "/";
  }
  if (response.rtnCode && response.rtnCode === 401) {
    localStorage.clear();
    window.location = "/";
  }
};
export { verifyResponse, httpGet, httpPost };
