import styled from "@emotion/styled"
import { GlobalStyle } from "styles/global"

const mobileMaxWidth = GlobalStyle.mobileMaxWidth
const tabletMaxWidth = GlobalStyle.tabletMaxWidth

const LandingPageWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input:-internal-autofill-selected {
    background-color: white !important;
  }

  .mobile-content {
    display: none;
  }

  strong {
    font-family: Aeonik;
    font-weight: 700;
  }

  button {
    /* width: 227px; */
    font-family: Aeonik !important;
    width: fit-content;
    min-width: 348px;
    border: 0;
    border-radius: 0;
    background-color: #ffa300;
    color: black;
    font-weight: bold;
    line-height: 31.2px;
    font-size: 20px;
    padding: 18px 20px;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    cursor: pointer;
  }

  .logo {
    width: 200px;
  }
  .section-full {
    width: 100%;
  }

  .heading {
    font-family: Aeonik;
    font-weight: bold;
    font-size: 32px;
  }

  .sub-heading {
    font-family: Aeonik;
    font-style: normal;
    font-size: 32px;
    line-height: 120%;
  }

  .h1 {
    font-family: Aeonik;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    /* or 48px */
  }
  ul {
    margin: 40px 0;
    list-style: none;
    list-style-type: none;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 166%;
  }

  p {
    font-family: Aeonik;
    font-weight: 400;
    font-size: 16px;
    line-height: 166%;
    .bold {
      font-weight: bold;
    }
  }

  .panel {
    width: 50%;
    display: flex;
    flex-direction: column;

    &.v-middle {
      justify-content: center;
    }

    &.h-middle {
      display: flex;
      align-items: center;
    }

    &.left {
    }
    &.right {
    }
  }

  .input-wrapper {
    display: inline-block;
    width: fit-content;
    &.has-icon {
      /* width: calc(100% - 50px); */
      position: relative;
      svg {
        position: absolute;
        top: 31.6%;
        left: 20px;
        color: gray;
      }

      input {
        &:nth-of-type(1) {
          padding-left: 50px !important;
          width: calc(100% - 50px) !important;
        }
      }
    }
  }
  .show-all-the-time {
    display: block !important;
  }

  .non-desktop {
    display: none;
  }

  .mobile-only {
    display: none;
  }

  .tablet-only {
    display: none;
  }

  @media (max-width: ${tabletMaxWidth}) {
    .desktop-only {
      display: none;
    }
    .non-desktop {
      display: block;
    }
    .tablet-only {
      display: block;
    }
  }
  @media (max-width: ${mobileMaxWidth}) {
    button {
      min-width: auto;
      font-size: 16px;
      line-height: 28px;
    }
    .mobile-only {
      display: block;
    }
    .tablet-only {
      display: none;
    }
  }
`

const PageContent = styled.div`
  max-width: 1158px;
  width: calc(100% - 40px);
  margin: 0 auto;
  @media (max-width: ${mobileMaxWidth}) {
    width: calc(100% - 60px);
    p {
      font-size: 16px;
      line-height: 120%;
    }
  }
`

const LadingPageBanner = styled.div`
  background-color: #a83fb0;
  /* padding: 30px 0 20px 0; */
  padding: 20px 0 20px 0;
  width: 100%;
  color: white;
  position: relative;
  video {
    position: absolute;
    z-index: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    &::-webkit-media-controls {
      display: none !important;
      -webkit-appearance: none !important;
    }
    &::-webkit-media-controls-play-button {
      display: none !important;
      -webkit-appearance: none !important;
    }
    &::-webkit-media-controls-start-playback-button {
      display: none !important;
      -webkit-appearance: none !important;
    }
    &::-webkit-media-controls-start-playback-button {
      display: none !important;
      -webkit-appearance: none !important;
    }
  }

  .content {
    position: relative;
    z-index: 1;
  }

  .prompt {
    display: flex;

    font-weight: 500;
    font-size: 12px;
    line-height: 156%;
    align-items: center;
    img {
      width: 15px;
      height: 15px;
      margin-left: 2px;
      margin-right: 5px;
    }
  }

  .waiting-list-panel {
    .message {
      height: 25px;
      font-size: 18px;
      line-height: 120%;
      padding: 5px 0px 0px 5px;
      color: #00000085;
      &.error {
        color: #ffffff5e;
      }

      .loading-icon {
        display: flex;

        img {
          width: 60px;
          margin: -7px -6px 0 -17px;
        }
      }
    }
  }

  .main-panel {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
  }

  h2 {
    margin: 0;
    font-family: Aeonik;
    font-weight: 700;
    font-size: 50px;
  }

  p {
    margin-top: 60px;
    font-size: 18px;
    max-width: 500px;
    line-height: 21.6px;
  }

  button {
    border-radius: 0;
  }

  .submit-btn {
    width: 240px;
    min-width: auto;
  }

  .submit-btn-mobile {
    width: 100%;
    border-radius: 12px;
    display: none;
  }

  .outline {
    font-size: 18px;
    font-family: Aeonik;
    font-weight: 700;
    font-style: italic;
  }

  .left-panel {
    width: 70%;
  }

  .right-panel {
    width: 30%;
    display: flex;
    justify-content: right;

    img {
      max-height: 660px;
    }
  }

  .waiting-list-panel {
    margin-top: 55px;
    input {
      background-color: #ffffff42;
      color: white;
      border: 0;
      padding: 13px 20px;
      width: 247px;
      height: 46px;
      font-size: 20px;

      &.email-input {
        width: 258px;
      }

      &:focus {
        border: 0;
        outline: 0;
      }

      &.right-split-line {
        border-right: 1px solid #ffffff29;
      }

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #ffffff7d;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #ffffff7d;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #ffffff7d;
      }
    }

    .input-row-wrapper {
      border-radius: 12px;
      width: fit-content;
      overflow: hidden;
      margin: 12px 0;
      display: flex;
      align-items: stretch;
      height: 72px !important;
      input {
        border-radius: 0;
        height: 100%;
        padding: 0 20px;
      }

      button {
        border-radius: 0;
        height: 100%;
        padding: 0;
      }

      &.has-icon {
        position: relative;
        svg {
          position: absolute;
          top: 32.6%;
          left: 20px;
          color: rgba(255, 255, 255, 0.7);
        }

        input {
          &:nth-of-type(1) {
            padding-left: 55px !important;
          }
        }
      }
    }
  }

  .recommendation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
    margin-bottom: 20px;

    &.tablet {
      display: none;
    }

    .avatar {
      margin-right: 20px;
      width: 64px;
      height: 64px;
    }

    .desc {
      font-size: 16px;
      width: 100%;
      line-height: 24px;
    }
  }

  @media (max-width: ${tabletMaxWidth}) {
    .left-panel {
      width: 60% !important;
      h2 {
        font-size: 40px !important;
      }
      p {
        font-size: 16px !important;
      }
      .outline {
        font-size: 16px !important;
      }
      .waiting-list-panel {
        .input-row-wrapper {
          input {
            width: 200px;
          }
          .email-input {
            width: 178px !important;
          }
          .submit-btn {
            width: 227px;
          }
        }
      }
    }
    .right-panel {
      width: 40% !important;
      align-items: center !important;
      justify-content: center !important;
      overflow: hidden;
      img {
        max-height: 480px !important;
        /* margin: -115px 0 0 0; */
      }
    }
    .recommendation {
      display: none;
      &.tablet {
        display: flex;
        margin-top: 40px;
        margin-bottom: 80px;
      }
    }
  }

  @media (max-width: 841px) {
    .left-panel {
      h2 {
        font-size: 36px !important;
        text-align: left;
      }
    }
  }

  @media (max-width: calc(${tabletMaxWidth} - 300px)) {
    .left-panel {
      p {
        font-size: 16px !important;
      }

      .outline {
        font-size: 16px !important;
        margin: 0 auto;
      }
      .waiting-list-panel {
        margin-top: 35px;
        .input-row-wrapper {
          .email-input {
            width: 150px !important;
          }
          input {
            width: 160px;
            font-size: 16px;
          }
        }
        .submit-btn {
          width: 175px !important;
          font-size: 16px;
        }
      }
      .recommendation {
        margin-top: 30px;
      }
    }
  }

  @media (max-width: 761px) {
    .left-panel {
      h2 {
        font-size: 32px !important;
        text-align: left;
      }
    }
  }

  @media (max-width: ${mobileMaxWidth}) {
    .mobile-content {
      display: block;
      text-align: center;
      max-width: none;
      margin-top: 33px;
    }
    .non-mobile-content {
      display: none;
    }

    .content {
      display: flex;
      flex-direction: column;
    }
    .logo {
      margin: 0 auto;
    }
    .left-panel {
      h2 {
        font-size: 27.5px !important;
        text-align: center;
        margin-top: 16px;
      }
      /* p {
        font-size: 16px !important;
      } */
      .outline {
        margin-top: 15px;
        max-width: 260px !important;
        font-style: normal;
      }

      .prompt {
        margin-top: 10px;
      }

      width: 100% !important;

      .waiting-list-panel {
        padding: 0 10px;
        .input-row-wrapper {
          width: 100% !important;
          height: 56px !important;
          input {
            width: 50%;
            font-family: Aeonik;
            font-size: 16px;
            font-weight: 500;
          }
          .email-input {
            width: 100% !important;
          }
        }
        .submit-btn {
          display: none;
        }
        .submit-btn-mobile {
          display: block;
          font-size: 16px;
          min-width: auto;
          line-height: 28px;
        }
      }
    }
    .right-panel {
      display: none !important;
    }

    .recommendation {
      flex-direction: column;
      align-items: center;
      display: flex;
      margin-top: 5px !important;
      &.tablet {
        display: none;
      }
      .desc {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
  }
`

const LandingCompaniesStory = styled.div`
  display: flex;
  padding: 110px 0 60px 0;

  @media (max-width: ${mobileMaxWidth}) {
    padding: 80px 0 30px 0 !important;
    .info {
      .desc {
        font-size: 16px !important;
        width: 70%;
        margin: 0 auto;
        margin-top: 20px !important;
      }

      .companies {
        margin: 70px 0 0 0 !important;
        .msg {
          margin-bottom: 15px !important;
          font-size: 11px !important;
        }
      }

      .rating {
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        .stars {
          padding: 10px 0;
        }
        .label {
          font-family: Aeonik;
          font-weight: 700;
          font-size: 14px;
          line-height: 120%;
        }
      }
    }
  }

  .info {
    text-align: center;
    .heading {
      font-family: Aeonik;
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
    }
    .desc {
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: 1px;
      margin-top: 10px;
    }
    .rating {
      margin: 5px 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .label {
        display: inline-block;
        margin-left: 10px;
      }
      svg {
        fill: orange;
      }
    }

    .companies {
      margin: 125px 0 0 0;
      img {
        width: 100%;
      }
      .msg {
        font-weight: 400;
        font-size: 14px;
        line-height: 166%;
        color: #767676;
        margin-bottom: 25px;
        padding: 0 20px;
      }
    }
  }
`

const LadingAppIntro = styled.div`
  @media (max-width: ${tabletMaxWidth}) {
    .content {
      flex-direction: column-reverse !important;
      .panel {
        width: 100% !important;
        ul {
          li {
            font-size: 16px;
            line-height: 120%;
          }
        }
        &.right {
          img {
            max-width: 530px;
            margin: 0 auto 50px auto;
          }
        }
        &.left {
          width: 50%;
          padding-right: 0px;
          .heading {
            font-size: 28px !important;
          }
          .sub-heading {
            font-size: 28px !important;
          }
          button {
            width: 100%;
          }
          p {
            margin: 15px 0 40px 0;
          }
        }
      }
    }
  }
  @media (max-width: ${mobileMaxWidth}) {
    .content {
      .panel {
        &.left {
          width: 100% !important;
          padding-right: 0px;
          .heading {
            font-size: 20px !important;
          }
          .sub-heading {
            font-size: 20px !important;
          }
          p {
            margin: 0px 0 15px 0;
          }
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;

    ul {
      margin: 25px 0;
      li {
        display: flex;
        align-items: center;
        padding: 1px 0;
        font-size: 20px;
        line-height: 166%;
        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
    }
  }

  margin: 40px 0;

  .panel {
    width: 50%;
    font-family: Aeonik;
    &.left {
      width: calc(50% - 60px);
      font-style: normal;
      font-size: 32px;
      line-height: 120%;
      padding-right: 60px;
      p {
        font-family: Aeonik;
        font-weight: 700;
        margin: 0px 0 15px 0;
      }
      button {
        margin-top: 20px;
        text-align: center;
        width: 345px;
        height: 64px;
      }
    }

    &.right {
      width: 50%;
      text-align: center;
      img {
        width: 100%;
      }
    }
  }
`

const LandingAudioTrail = styled.div`
  display: flex;
  width: 100%;
  background-image: url("/images/audio-section-background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: ${tabletMaxWidth}) {
    .content {
      .h1 {
        text-align: center;
      }
      min-height: auto !important;
    }
  }
  @media (max-width: ${mobileMaxWidth}) {
    .content {
      .h1 {
        font-size: 24px;
      }
      .audio-preview-section {
        margin-top: -35px !important;
        p {
          text-align: center;
        }
        .audio-taps {
          ul {
            text-align: left;
            li {
              font-size: 14px;
            }
          }
        }
        .audio-player {
          margin-top: 20px;
        }
      }
    }
  }

  .audio-player {
    max-width: 850px;
  }

  .content {
    min-height: 600px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .audio-preview-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 490px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }

  figure {
    width: 80%;
    audio {
      width: 100%;
    }
  }

  p {
    max-width: 584px;
  }

  .audio-taps {
    margin-top: 40px;
    ul {
      list-style: none;
      text-align: center;
      li {
        display: inline-block;
        background-color: white;
        margin-right: 10px;
        margin-bottom: 10px;
        font-family: Aeonik;
        font-weight: 700;
        cursor: pointer;
        padding: 0 24px;
        height: 41px;
        border-radius: 100px;
        line-height: 40px;

        &.selected {
          background: #7a49a9;
          color: white;
        }
      }
    }
  }
`

const LandingTeam = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .content {
    width: 100%;
    background-image: url("/images/team-background-1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: top;

    &.alt {
      background-image: url("/images/team-background-2.png");
      background-position-x: right;
    }
  }

  .release-stress-section {
    padding-right: 60px;
    width: calc(50% - 60px);
  }
  .achieve-any-section {
    padding-right: 50px;
    width: calc(50% - 50px);
  }
  .improve-focus-section {
    padding-right: 55px;
    width: calc(50% - 55px);
  }

  @media (max-width: ${tabletMaxWidth}) {
    ul {
      padding-left: 20px !important;
    }
    .content {
      &.alt {
        .section {
          flex-direction: column-reverse !important;
        }
      }

      .release-stress-section,
      .achieve-any-section,
      .improve-focus-section {
        padding-right: 0;
        width: 50%;
      }
      .section {
        flex-direction: column !important;

        .h1 {
          font-size: 40px;
          text-align: center;
        }
        .heading {
          text-align: left;
          width: 100%;
          font-size: 28px;
          line-height: 120%;
        }
        .section-row {
          flex-direction: column !important;
          height: auto !important;
        }

        .panel {
          width: 100% !important;
          align-items: center !important;
          .phone-animation {
            margin: 80px 0;
          }
          button {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: ${mobileMaxWidth}) {
    ul {
      padding-left: 15px !important;
    }
    h1 {
      font-size: 24px !important;
    }
    .h1 {
      font-size: 24px !important;
      text-align: center;
    }
    .content {
      margin-top: 80px;
      background-image: url("/images/team-background-mobile-1.png");
      background-position: top;
      &.alt {
        background-image: url("/images/team-background-mobile-2.png");
      }

      .release-stress-section,
      .achieve-any-section,
      .improve-focus-section {
        padding-top: 20px;
      }

      br {
        display: none;
      }
      .section {
        flex-direction: column !important;

        .h1 {
          font-size: 24px !important;
          text-align: center;
        }
        .heading {
          text-align: left;
          width: 100%;
          font-size: 20px;
        }
        .panel {
          .phone-a {
            width: 75%;
            max-width: 300px !important;
            margin: 10% 0 !important;
          }
          .phone-animation {
            margin: 0;
            max-width: 360px;
          }
        }
      }
    }
  }

  .h1 {
    margin-top: 90px;
  }

  .section {
    display: flex;
    flex-direction: row;
    min-height: 800px;
    width: 100%;

    .section-row {
      display: flex;
      flex-direction: row;
      background-color: transparent;
      height: 790px;
    }

    &.col {
      flex-direction: column;
    }
  }
  ul {
    list-style: disc;
    padding-left: 25px;
  }

  .phone-b-img {
    width: 580px;
  }
  .phone-c-img {
    width: 540px;
  }
`

const LandingExperts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
  padding-top: 100px;
  width: 100%;

  @media (max-width: ${mobileMaxWidth}) {
    min-height: 430px;
    padding-top: 60px;
    h1 {
      font-size: 24px !important;
    }

    .h1 {
      font-size: 24px !important;
      text-align: center;
    }
    .heading {
      text-align: left;
      width: 100%;
      font-size: 20px;
    }
    .testimonial-slider {
      margin-top: 0 !important;
    }
  }

  .h1 {
    margin: 20px 0;
  }

  .testimonial-slider {
    margin-top: 45px;
  }
`

const LandingTestimonial = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 700px;
  padding-top: 100px;
  width: 100%;
  background-image: url("/images/testimonial-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: ${mobileMaxWidth}) {
    flex-direction: column;
    padding-top: 0px;
    min-height: 790px;
    background-size: 750px;
    background-repeat: repeat;

    h1 {
      font-size: 24px !important;
    }

    .h1 {
      font-size: 24px !important;
      text-align: center;
    }
    .heading {
      text-align: left;
      width: 100%;
      font-size: 20px;
    }

    .panel {
      width: 100% !important;
    }
    .info-box {
      box-shadow: none !important;
      min-height: 230px !important;
      padding: 25px 25px 10px 25px;
      text-align: center;
    }
    .testimonial-slider {
      margin-top: 120px;
      box-shadow: none !important;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
  }

  .info-box {
    background: #f8f8f8;
    box-shadow: 5px 0px 4px rgba(0, 0, 0, 0.25);
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .h1,
    p {
      max-width: 426px;
    }

    z-index: 2;
  }

  .testimonial-slider {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
`

const LandingPhonePreview = styled.div`
  width: 100%;
  margin-top: 100px;
  .content {
    display: flex;
    background-position: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* min-height: 400px; */
  }

  .preview-img {
    width: 100%;
  }

  .h1 {
    margin: 44px 0 30px 0;
    text-align: center;
  }
  .login-panel {
    margin-top: 60px;

    button {
      width: 339px;
    }
    .input-group-name {
      display: inline-flex;
      border: 1px solid #a6a6a6;
      border-radius: 12px;
      margin-right: 10px;
      justify-content: space-between;
      padding: 1px;
      align-items: center;
      width: 358px;
      overflow: hidden;
      input {
        border: 0;
        border-radius: 0;
        width: 49.5%;
      }
      .separator {
        height: 35px;
        width: 1px;
        background-color: #a6a6a6;
        display: inline-block;
      }
    }
    input {
      height: 25px;
      font-size: 18px;
      background: #ffffff;
      border: 1px solid #a6a6a6;
      border-radius: 12px;
      padding: 20px 15px;
      &:focus {
        outline: none;
      }
    }

    .input-text-email {
      width: 340px;
      margin-right: 27px;
    }
  }

  .prompt {
    max-width: 1080px;
    width: 100%;
    display: flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 156%;
    align-items: flex-start;
    margin-top: 10px;
    color: #707370;
    img {
      width: 15px;
      height: 15px;
      margin-left: 2px;
      margin-right: 5px;
    }
  }

  .message {
    text-align: left;
    width: calc(100% - 35px);
    padding: 5px 0 0 35px;
    font-size: 18px;
    color: #707370;

    &.error {
      color: red;
    }
    .loading-icon {
      display: flex;
      img {
        width: 60px;
        margin: -7px -6px 0 -17px;
      }
    }
  }

  @media (max-width: ${tabletMaxWidth}) {
    flex-direction: column;
    .prompt {
      max-width: 600px;
      margin-top: 0px;
    }
    .login-panel {
      max-width: 600px;
      .input-group-name {
        /* width: calc(100% - 5px) !important; */
        width: 100%;
        margin-bottom: 20px;
      }
      .input-text-email {
        width: calc(50% - 20px) !important;
        margin: 0;
        margin-bottom: 20px;
        margin-right: 30px;
      }
      button {
        width: 290px !important;
      }
    }

    .message {
      width: 600px;
      padding: 5px 0 0 0pc;
      .loading-icon {
        padding: 0;
      }
    }
  }

  @media (max-width: ${mobileMaxWidth}) {
    flex-direction: column;
    margin-top: 80px;
    h1 {
      font-size: 24px !important;
      br {
        display: none !important;
      }
    }
    .h1 {
      font-size: 24px !important;
      text-align: center;
      margin: 0 20px !important;
      br {
        display: none !important;
      }
    }

    .prompt {
      max-width: none;
      margin-top: 10px;
      padding: 0 30px;
      width: calc(100% - 60px);
    }

    .login-panel {
      padding: 0 30px;
      .input-group-name {
        width: calc(100% - 6px) !important;
        margin-right: 0;
        margin-bottom: 20px;
      }
      .input-text-email {
        width: calc(100% - 20px) !important;
        margin: 0;
        margin-bottom: 20px;
      }
      button {
        width: 100% !important;
      }
    }

    .message {
      width: calc(100% - 35px);
      padding: 5px 0 0 35px;
      .loading-icon {
        padding: 0;
        font-size: 14px;
        img {
          width: 45px;
          /* margin: -5px -8px 0 -12px; */
        }
      }
    }
  }
`

const LandingPageQuestions = styled.div`
  width: 100%;

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 0;
  }

  p {
    margin-bottom: 60px;
  }

  .question {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    &.expending {
      .title {
        svg {
          transform: rotate(-180deg);
        }
      }
      .answer {
        max-height: 520px;
        margin: 20px 0;
      }
    }

    .title {
      transition: all 0.5s ease;
      font-family: Aeonik;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 165%;
      color: rgba(0, 0, 0, 0.8);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      margin-top: 40px;
      svg {
        width: 33px;
        height: 35px;
        cursor: pointer;
      }
    }
    .answer {
      transition: all 0.5s ease;
      max-height: 0;
      font-family: Aeonik;
      font-style: normal;
      font-size: 16px;
      line-height: 140%;
      padding: 0 10px;
    }
  }

  @media (max-width: ${mobileMaxWidth}) {
    h1 {
      font-size: 24px !important;
    }
    .h1 {
      font-size: 24px !important;
      text-align: center;
    }
    .question {
      .title {
        font-size: 17px;
        margin-top: 20px;
      }
    }
    .answer {
      font-size: 15px;
    }
  }
`

const LandingPageFooter = styled.div`
  display: flex;
  min-height: 190px;
  background-color: #6c3992;
  width: 100%;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 180px;
    }

    .info-region {
      display: flex;
      align-items: center;

      .links {
        button {
          font-family: Aeonik;
          font-weight: 700;
          text-decoration: none;
          color: white;
          font-size: 16px;
          line-height: 120%;
          margin-left: 20px;
          padding: 5px;
          width: fit-content;
          background-color: transparent;
          box-shadow: none;

          &:hover {
            color: #e1d0d0;
          }
        }
      }
      img {
        width: 155px;
        margin-left: 40px;
      }
    }
  }

  @media (max-width: ${mobileMaxWidth}) {
    .content {
      width: 100%;
      margin: 35px 0;
      justify-content: space-between;
      flex-direction: column-reverse;

      .logo {
        margin-top: 25px;
      }

      .info-region {
        flex-direction: column;
        align-items: center;
        height: 100px;
        display: flex;
        justify-content: center;
        img {
          margin-left: 0;
          margin-top: 20px;
          margin-bottom: 25px;
        }

        .links {
          button {
            display: block;
            margin-left: 0;
          }
        }
      }
    }
  }
`
export {
  LandingPageWrapper,
  LadingPageBanner,
  PageContent,
  LandingCompaniesStory,
  LadingAppIntro,
  LandingTeam,
  LandingAudioTrail,
  LandingExperts,
  LandingTestimonial,
  LandingPhonePreview,
  LandingPageQuestions,
  LandingPageFooter,
}
