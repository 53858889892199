import styled from "@emotion/styled"
import { Dialog } from "@mui/material"
import { GlobalStyle } from "styles/global"

const DownloadAppSharePageWrapper = styled.div`
  display: flex;
  background-color: rgba(131, 71, 173, 0.13);
  width: 100%;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;

  .logo {
    margin: 20px 0;
    img {
      width: 100px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 452px;
    width: calc(100% - 40px);
    padding: 0 20px;
    height: 537px;
    background-color: white;

    .welcome {
      font-family: Aeonik;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: ${GlobalStyle.colors.lightGreen};
      padding: 22px 0;
    }

    .heading {
      font-family: Aeonik;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      /* identical to box height, or 29px */
      text-align: center;
      padding: 10px 0;
    }

    .sub-heading {
      font-family: Aeonik;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      max-width: 350px;
      text-align: center;
    }

    .qr-code {
      background: url(Screen Shot 2023-03-08 at 8.33.png);
      box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      width: 160px;
      height: 160px;
      margin: 40px 0;
      canvas {
        border: 1px solid #00000012;
        border-radius: 7px;
      }
    }

    .app-store {
      width: 95px;
    }

    .action-link {
      margin-top: 40px;
      font-size: 14px;
      color: #0500ff;
      padding: 2px 0;
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid #0500ff;
      background-color: transparent;
      cursor: pointer;
    }

    .nav-back {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      /* padding: 15px; */

      a {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;
      }

      svg {
        margin-right: 10px;

        width: 30px;
        height: 30px;
      }
    }

    .link-sent-title {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: center;
      padding: 15px;
      align-items: center;
      font-family: Aeonik;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;

      svg {
        margin-right: 5px;
        fill: #789c4a;
        width: 30px;
        height: 30px;
      }
    }

    .email {
      font-family: Aeonik;
      font-weight: 700;
      font-style: normal;
      font-size: 16px;
      line-height: 120%;
    }
  }
`

const DownloadAppSendLinkDlgWrapper = styled(Dialog)`
  display: flex;

  .MuiDialogTitle-root {
    display: flex;
    position: relative;
    svg {
      position: absolute;
      right: 12px;
      top: 19px;
      cursor: pointer;
    }
  }

  .MuiDialog-container {
    margin: 0 auto;
    .MuiDialog-paper {
      min-width: 600px;
    }
  }

  .action-panel {
    margin-top: 20px;
    display: flex;
    .target-input {
      position: relative;
      display: inline-block;
      width: 236px;
      height: 34px;
      margin-right: 50px;

      input {
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100% - 30px);
        height: 100%;
        background: rgb(255, 255, 255);
        border: 1px solid rgb(141, 141, 141);
        border-radius: 4px;
        padding: 0px 20px 0 35px;
      }
      svg {
        position: absolute;
        z-index: 2;
        top: 6px;
        left: 10px;
        width: 20px;
      }
    }

    button {
      font-family: Aeonik;
      font-weight: 700;
      width: 259px;
      height: 37px;
      border: 0;
      /* light orange */
      background: #ffa300;
      border-radius: 4px;
      font-size: 16px;
      line-height: 166%;
      cursor: pointer;
    }
  }
`

export { DownloadAppSharePageWrapper, DownloadAppSendLinkDlgWrapper }
