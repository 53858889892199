import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent } from "firebase/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyAJQmEA4H2CiZlI1k1naxOhZZNrgYe0q0U",
  authDomain: "innercise-6529e.firebaseapp.com",
  projectId: "innercise-6529e",
  storageBucket: "innercise-6529e.appspot.com",
  messagingSenderId: "624290504808",
  appId: "1:624290504808:web:3fa2540c958051e0399bdd",
  measurementId: "G-ED8KBS8J9S",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const LogFirebaseEvent = (eventName, prams) => {
  logEvent(analytics, eventName, prams)
}

export { LogFirebaseEvent, analytics }
