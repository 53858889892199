import React, { useState, useEffect, useRef } from "react"
import ReactPlayer from "react-player/lazy"
import PlayCircleIcon from "@mui/icons-material/PlayCircle"
import PauseCircleIcon from "@mui/icons-material/PauseCircle"
import {
  AudioPlayerWrapper,
  AudioPlayerControlPanel,
} from "./AudioPlayer.style"

const AudioPlayer = ({ url, title }) => {
  const [playing, setPlaying] = useState(false)
  const [playTime, setPlayTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [progressRate, setProgressRate] = useState(0)

  const playerRef = useRef(null)

  const onPlayButtonAction = () => {
    setPlaying(playing ? false : true)
  }

  const seekTo = (e) => {
    const rect = e.target.getBoundingClientRect()
    const width = rect.right - rect.left
    const xPosition = e.clientX - rect.left //x position within the element.
    const progressRate = xPosition / width
    playerRef.current.seekTo(duration * progressRate, "seconds")
  }

  useEffect(() => {
    setPlayTime(0)
  }, [url])

  return (
    <AudioPlayerWrapper className="audio-player">
      <ReactPlayer
        ref={playerRef}
        url={url}
        playing={playing}
        className="player"
        width="0"
        height="0"
        onProgress={(info) => {
          setPlayTime(info.loadedSeconds)
          setProgressRate(
            info.playedSeconds / duration > 1
              ? 1
              : info.playedSeconds / duration
          )
        }}
        onReady={(e) => {
          setDuration(playerRef.current.getDuration())
        }}
      />

      <AudioPlayerControlPanel progress={parseInt(progressRate * 100)}>
        <div className="title">{title}</div>
        <div className="timeline">
          {playing ? (
            <PauseCircleIcon
              className="play-btn"
              onClick={onPlayButtonAction}
            />
          ) : (
            <PlayCircleIcon className="play-btn" onClick={onPlayButtonAction} />
          )}
          <div className="time-label">0.0</div>
          <div className="bar">
            <div className="played-bar"></div>
            <div className="control-bar" onClick={seekTo}></div>
          </div>
          <div className="time-label">{`${parseInt(duration)} secs`}</div>
        </div>
      </AudioPlayerControlPanel>
    </AudioPlayerWrapper>
  )
}

export default AudioPlayer
