import React, { useEffect, useState, useRef } from "react"
import PageTitle from "components/common/PageTitle/PageTitle"
import StarOutlinedIcon from "@mui/icons-material/StarOutlined"
import DraftsIcon from "@mui/icons-material/Drafts"
import { useNavigate } from "react-router-dom"
import { validateEmail } from "utils/validation"
import { getReferralCode } from "services/marketing"
import { addActionToSpreadSheet } from "services/marketing"
import { verifyResponse } from "utils/httpRequest"
import {
  WaitingListWrapper,
  WaitingListBanner,
  PageContent,
  LandingPageFooter,
} from "./WaitingList.style"

const WaitingList = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const emailExist = useRef(false)
  const formRef = useRef()
  const emailRef = useRef("")

  // get url params variables
  const queryParams = new URLSearchParams(window.location.search)
  const referralBy = queryParams.get("referred")
  const utmMedium = queryParams.get("utm_medium")
  const utmSource = queryParams.get("utm_source")
  const utmCampaign = queryParams.get("utm_campaign")
  const utmContent = queryParams.get("utm_content")
  const utmAffiliate = queryParams.get("utm_affiliate")

  const navigate = useNavigate()

  const validate = () => {
    let isValid = false
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === ""
    ) {
      setErrorMessage("* All fields are required.")
    } else if (!validateEmail(email.trim())) {
      setErrorMessage("* please input valid email address.")
    } else {
      setErrorMessage("")
      isValid = true
    }
    return isValid
  }

  const addRecordGoogleSheet = async ({
    action,
    firstName,
    lastName,
    email,
    utmMedium,
    utmSource,
    utmAffiliate,
    utmCampaign,
    utmContent,
  }) => {
    try {
      setLoading(true)
      const response = await addActionToSpreadSheet({
        action,
        first: firstName,
        last: lastName,
        email,
        utm_medium: utmMedium,
        utm_source: utmSource,
        utm_affiliate: utmAffiliate,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
      })
      verifyResponse(response)
    } catch (err) {
      console.error("error: ", err)
    } finally {
      setLoading(false)
    }
  }

  const checkReferralCode = async () => {
    setLoading(true)
    const response = await getReferralCode(email)
    setLoading(false)

    // set record status
    if (response.data.type === "exist" && response.data.lead) {
      emailExist.current = true
    }
    // return referral code
    return response.data.refCode
  }

  const redirectConfirmationPage = () => {
    const forwardLink = `/waitlist/confirmation?hemail=${emailRef.current}`
    console.log("forwardLink: ", forwardLink)
    setTimeout(() => {
      navigate(forwardLink)
    }, 1000)
  }

  const initMarketo = () => {
    window.MktoForms2.loadForm(
      "//join.myneurogym.com",
      "519-VOW-812",
      1132,
      (form) => {
        form.onSubmit(() => {
          setFormSubmitted(true)
        })
        form.onSuccess(function (values, followUpUrl) {
          // On Success
          console.log("Success Submit Marketo")
          form.getFormElem().hide()
          // Redirect after processing on your side to confirmation page
          redirectConfirmationPage()
          return false
        })
        formRef.current = form
      }
    )
  }

  useEffect(() => {
    // init Marketo
    initMarketo()
  }, [])

  const submitAction = async () => {
    setSubmitted(true)
    // valid input
    if (!validate()) {
      return false
    }
    // request a new referralCode
    const referralCode = await checkReferralCode()

    // redirect to confirmation page if record exist
    emailExist.current && navigate(`/waitlist/confirmation?hemail=${email}`)

    // post data to Marketo
    formRef.current.setValues({
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      ReferralCode: referralCode,
      ReferralBy: referralBy || "",
    })
    console.log("Marketo Ready to Submit")

    // sync to google sheet
    await addRecordGoogleSheet({
      action: "web-waitlist",
      firstName,
      lastName,
      email,
      utmMedium: utmMedium || "",
      utmSource: utmSource || "",
      utmAffiliate: utmAffiliate || "",
      utmCampaign: utmCampaign || "",
      utmContent: utmContent || "",
    })

    // submit form to marketo
    formRef.current.submit()
  }

  return (
    <WaitingListWrapper>
      <PageTitle title="Waiting List Page" />
      <WaitingListBanner>
        <video
          className="fullscreen"
          src="/media/waitinglist-bg-video-720.mp4"
          playsInline
          autoPlay
          muted
          loop
        ></video>
        <PageContent className="content">
          <img className="logo" src="/images/Innercise_logo.png" alt="logo" />
          <div className="main-panel">
            <div className="left-panel">
              <h1>
                A Mindset Coach In the
                <br /> Palm Of Your Hand
              </h1>

              <h2>
                Imagine A Mindset Coach <br />
                In the Palm Of Your Hand
              </h2>

              <p>
                Just like exercise revolutionized physical health...
                <br />
                The innercise® app is revolutionizing mindset training & mental
                fitness
              </p>
              <div className="outline">
                Join the Waiting List Now & Win Up to $100,000 in Cash and
                Prizes
              </div>

              <div className="waiting-list-panel">
                <div className="input-row-wrapper">
                  <input
                    id="input-first-name"
                    placeholder="First name*"
                    className="right-split-line"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                    }}
                  />
                  <input
                    id="input-last-name"
                    placeholder="Last name*"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value)
                    }}
                  />
                </div>
                <div className="input-row-wrapper has-icon">
                  <DraftsIcon />
                  <input
                    id="input-email"
                    placeholder="Your email address*"
                    className="email-input"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                      emailRef.current = e.target.value
                    }}
                  />
                  <button
                    onClick={() => {
                      submitAction()
                    }}
                  >
                    Join Now for Free
                  </button>
                </div>
                <button
                  className="mobile-btn"
                  onClick={() => {
                    submitAction()
                  }}
                >
                  Join Now for Free
                </button>
                <div className="prompt">
                  <img src="/images/shield.png" alt="shield icon" />
                  We will not spam, rent, or sell your information.
                </div>
                <div
                  className={`message ${
                    submitted && errorMessage !== "" && "error"
                  }`}
                >
                  {loading && (
                    <div className="loading-icon">
                      <img src="/images/loading.gif" alt="loading icon" />
                      loading ...
                    </div>
                  )}
                  {submitted && errorMessage}
                  {formSubmitted && "Data submitted, waiting for redirect ..."}
                </div>
              </div>

              <div className="recommendation">
                <div className="rating">
                  <div>
                    <StarOutlinedIcon />
                    <StarOutlinedIcon />
                    <StarOutlinedIcon />
                    <StarOutlinedIcon />
                    <StarOutlinedIcon />
                  </div>
                  <div className="label">Over 100,000 success stories</div>
                </div>
                <div className="person">
                  <img
                    src="/images/person-1.png"
                    alt="person"
                    className="avatar"
                  />
                  <div className="desc">
                    “Innercises™ are cutting-edge techniques to maximize your
                    fullest potential.” <br />
                    <i>- Harvard University - Dr. Srini Pillay</i>
                  </div>
                </div>
              </div>
            </div>

            <div className="right-panel">
              <img
                className="right-mask"
                src="/images/waitinglist/waiting-list-banner-phone-mask.png"
                alt="phone"
              />

              <img
                className="right-mask-mobile"
                src="/images/waitinglist/waiting-list-iphone-mobile.png"
                alt="phone"
              />
            </div>
          </div>
        </PageContent>
      </WaitingListBanner>
      <LandingPageFooter>
        <PageContent className="content">
          <img
            className="logo"
            src="/images/Innercise_logo.png"
            alt="innercise white logo"
          />
          <div className="info-region">
            <div className="links">
              <button
                onClick={() => {
                  console.log("go to terms and condition")
                  navigate("/terms")
                }}
              >
                Terms of Use
              </button>
              <button
                onClick={() => {
                  navigate("/policy")
                }}
              >
                Privacy Policy
              </button>
            </div>
          </div>
        </PageContent>
      </LandingPageFooter>
    </WaitingListWrapper>
  )
}

export default WaitingList
