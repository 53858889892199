import styled from "@emotion/styled"

const StyledPhoneAnimateA = styled.div`
  display: flex;
  position: relative !important;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1547/2340;
  max-width: 400px;
  width: 100%;
`

const StyledPhoneFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-image: url("/images/phoneA-frame.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 10;
`
const StyledPhoneAnimateContent = styled.div`
  position: absolute;
  width: 65.1%;
  top: 0%;
  left: 17.5%;
  display: flex;
  img {
    width: 100%;
  }
`

export { StyledPhoneAnimateA, StyledPhoneFrame, StyledPhoneAnimateContent }
