import { httpPost } from "utils/httpRequest"

const getReferralCode = async (email) => {
  const resp = await httpPost("web/home/getReferralCode", { email })
  return resp.data
}

const addActionToSpreadSheet = async ({
  action,
  first,
  last,
  email,
  utm_medium,
  utm_source,
  utm_affiliate,
  utm_campaign,
  utm_content,
}) => {
  const resp = await httpPost("web/home/syncToSpreadSheet", {
    action,
    first,
    last,
    email,
    utm_medium,
    utm_source,
    utm_affiliate,
    utm_campaign,
    utm_content,
  })
  return resp.data
}
export { getReferralCode, addActionToSpreadSheet }
