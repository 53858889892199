import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getDiscoverNewPrograms } from "services/program";
import { verifyResponse } from "utils/httpRequest";
import BodyContentContainer from "components/common/LayoutComponents/BodyContentContainer";
import {
  StyledDiscoverNewProgram,
  StyledDiscoverNewProgramContainer,
  StyledDiscoverNewProgramItem,
} from "./DiscoverNewProgram.style";

const DiscoverNewProgram = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);

  const loadDiscoverNewPrograms = async () => {
    setLoading(true);
    const response = await getDiscoverNewPrograms();
    setLoading(false);
    verifyResponse(response);
    setPrograms(response.data.list);
  };

  useEffect(() => {
    loadDiscoverNewPrograms();
  }, []);

  return (
    <StyledDiscoverNewProgram>
      <BodyContentContainer>
        <h3>Discover New Programs</h3>
        <StyledDiscoverNewProgramContainer>
          {programs.map((program, programIndex) => {
            return (
              <StyledDiscoverNewProgramItem
                key={`discover-new-program-${programIndex}`}
                onClick={() => {
                  navigate(`/program/${program.id}`);
                }}
              >
                <img src={program.thumbResourceDto.urlDto.imgUrl_SM} alt="" />
                <div className="title">{program.title}</div>
              </StyledDiscoverNewProgramItem>
            );
          })}
        </StyledDiscoverNewProgramContainer>
      </BodyContentContainer>
    </StyledDiscoverNewProgram>
  );
};

export default DiscoverNewProgram;
