import React, { useState, useEffect } from "react"
import { useInterval } from "react-interval-hook"
import {
  StyledPhoneAnimateA,
  StyledPhoneAnimateContent,
  StyledPhoneFrame,
} from "./PhoneAnimateA.style"

const PhoneAnimateA = () => {
  const totalPhoneScreenCount = 8
  const [screenImageIndex, setScreenImageIndex] = useState(1)
  const [screenImagePath, setScreenImagePath] = useState("")

  const goNextImage = (index) => {
    setScreenImageIndex(index + 1 > totalPhoneScreenCount ? 1 : index + 1)
  }

  useInterval(
    () => {
      goNextImage(screenImageIndex)
    },
    2000,
    {
      autoStart: true,
      immediate: false,
      selfCorrecting: true,
      onFinish: () => {
        console.log("Callback when timer is stopped")
      },
    }
  )
  useEffect(() => {
    setScreenImagePath(`/images/PhoneA/${screenImageIndex}.png`)
  }, [screenImageIndex])
  return (
    <StyledPhoneAnimateA className="phone-animation phone-a">
      <StyledPhoneFrame />
      <StyledPhoneAnimateContent className="image">
        <img src={screenImagePath} alt={`screen`} />
      </StyledPhoneAnimateContent>
    </StyledPhoneAnimateA>
  )
}

export default PhoneAnimateA
