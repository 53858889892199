import styled from "@emotion/styled";

const StyledContinueListening = styled.div`
  display: flex;
  h3 {
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0;
  }
`;

const StyledContinueListeningContainer = styled.div`
  display: flex;
  margin: 20px 0;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
`;

const StyledContinueListeningItem = styled.div`
  /* border: 1px solid white; */
  cursor: pointer;
  width: 325px;
  height: 250px;
  margin-bottom: 15px;
  margin-right: 15px;
  .thumb {
  }
  padding: 0;
  /* background-color: gray; */
  border-radius: 3px;
  .title {
    margin: 7px 0 4px 5px;
    display: flex;
    align-items: center;
    .MuiBox-root {
      margin: 0 9px 9px 0px;
    }
  }
  .category {
    margin: 0 0 0 0;
    font-size: 12px;
    color: #a27373;
  }
`;

export {
  StyledContinueListening,
  StyledContinueListeningContainer,
  StyledContinueListeningItem,
};
