import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import BodyContentContainer from "components/common/LayoutComponents/BodyContentContainer";
import { getCategories } from "services/category";
import { verifyResponse } from "utils/httpRequest";
import {
  updateSelectCategoryId,
  updateCategories,
} from "state/reducers/categoryReducer";
import { useSelector, useDispatch } from "react-redux";
import { StyledCategoryMenu } from "./CategoryMenu.style";

const CategoryMenu = () => {
  let navigate = useNavigate();
  const categories = useSelector((state) => state.category.categories);
  const dispatch = useDispatch();

  const loadCategory = async () => {
    const response = await getCategories();
    verifyResponse(response);
    dispatch(updateCategories(response.data.categoryList));
  };

  useEffect(() => {
    loadCategory();
  }, []);

  useEffect(() => {
    dispatch(
      updateSelectCategoryId(categories.length > 0 ? categories[0].id : 0)
    );
  }, [categories]);

  return (
    <StyledCategoryMenu>
      <BodyContentContainer>
        <ul>
          {categories?.map((category, index) => {
            return (
              <li key={`category-list-item-${index}`}>
                <button
                  onClick={() => {
                    dispatch(updateSelectCategoryId(category.id));
                    setTimeout(() => {
                      navigate("/dashboard");
                    }, 0);
                  }}
                >
                  {category.name}
                </button>
              </li>
            );
          })}
        </ul>
      </BodyContentContainer>
    </StyledCategoryMenu>
  );
};

export default CategoryMenu;
