import { httpPost } from "utils/httpRequest";

const getProgramById = async (programId) => {
  const resp = await httpPost("client/program/queryById", {
    programId,
    keyword: null,
  });
  return resp.data;
};

const getProgramBySubCategoryId = async (categoryId) => {
  const resp = await httpPost("client/program/listByCategory", {
    categoryId,
    pageNo: 1,
    pageSize: 10000,
  });
  return resp.data;
};

const getInnerciseById = async (innerciseId) => {
  const resp = await httpPost("client/program/queryInnerciseById", {
    innerciseId,
  });
  return resp.data;
};

const getContinueInnercise = async () => {
  const resp = await httpPost("client/program/listContinuedInnercise", {
    pageNo: 1,
    pageSize: 999999,
  });
  return resp.data;
};

const getDiscoverNewPrograms = async () => {
  const resp = await httpPost("client/program/discoverNewPrograms", {
    pageNo: 1,
    pageSize: 999999,
  });
  return resp.data;
};

export {
  getProgramById,
  getProgramBySubCategoryId,
  getInnerciseById,
  getContinueInnercise,
  getDiscoverNewPrograms,
};
