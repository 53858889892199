import React, { useState, useEffect } from "react"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CloseIcon from "@mui/icons-material/Close"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone"
import { DownloadAppSendLinkDlgWrapper } from "./DownloadAppSharePage.style"

const DownloadAppSendLinkDlg = (props) => {
  const { emailAddress, submitEmailForm, submitTextForm } = props

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState("email")
  const [targetInput, setTargetInput] = useState("")

  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = () => {
    setOpen(true)
  }

  const validate = () => {
    let isValidate = true
    if (targetInput.trim() === "") {
      isValidate = false
    }
    return isValidate
  }

  const sendAction = () => {
    if (!validate()) {
      return false
    }

    if (type === "email") {
      // send email
      submitEmailForm(targetInput.trim())
    } else {
      // send sms
      submitTextForm(targetInput.trim())
    }
    setOpen(false)
  }

  useEffect(() => {
    type === "email" && setTargetInput(emailAddress)
  }, [])

  const dlg = (
    <DownloadAppSendLinkDlgWrapper open={open}>
      <DialogTitle>
        How do you want us to send you the link?
        <CloseIcon onClick={handleClose} />
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          row
          defaultValue="email"
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => {
            setType(e.target.value)
            e.target.value === "email"
              ? setTargetInput(emailAddress)
              : setTargetInput("")
          }}
        >
          <FormControlLabel
            value="email"
            control={<Radio />}
            label="Email Me"
          />
          <FormControlLabel value="sms" control={<Radio />} label="Text Me" />
        </RadioGroup>
        <div className="action-panel">
          <div className="target-input">
            <input
              type="text"
              placeholder={type === "email" ? "Email Address" : "Phone Number"}
              value={targetInput}
              onChange={(e) => {
                setTargetInput(e.target.value)
              }}
            />
            {type === "email" && <MailOutlineIcon />}
            {type === "sms" && <PhoneIphoneIcon />}
          </div>
          <button onClick={sendAction} disabled={targetInput.trim() === ""}>
            Send Me Link
          </button>
        </div>
      </DialogContent>
    </DownloadAppSendLinkDlgWrapper>
  )

  return [openDlg, dlg, loading]
}

export default DownloadAppSendLinkDlg
