import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router";

const LandingProgramWrapper = styled.div`
  /* min-height: calc(100vh - 140px); */
  height: 100vh;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
    max-width: 300px;
  }
`;

const LandingProgram = () => {
  const { programName } = useParams();

  useEffect(() => {
    //
  }, []);

  return (
    <LandingProgramWrapper>
      <img src="/images/logo.png" alt="logo" />

      {/* <Helmet>
        <meta
          property="og:url"
          content="https://myinnercise.com/program/Awareness"
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="1 MyInnercise.com title" />
        <meta property="og:article:title" content="2 MyInnercise.com title" />
        <meta
          property="og:description"
          content="1 How to clear your Facebook apps cache on an iPhone to help the app run more efficiently"
        />
        <meta
          property="og:article:description"
          content="2 How to clear your Facebook apps cache on an iPhone to help the app run more efficiently"
        />
        <meta property="og:article:section" content="Some description." />
        <meta
          property="og:image:url"
          content="https://innercise-image-dev.s3.amazonaws.com/image/8ef30b41e9a6e5f187c71271b1921966dbe6cccffd985ec63fd4b0deb630d839-image-20221016102938-sm.png"
        />
      </Helmet> */}

      {/* <MetaTags>
        <meta
          property="og:url"
          content="https://myinnercise.com/program/Awareness"
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="1 MyInnercise.com title" />
        <meta property="og:article:title" content="2 MyInnercise.com title" />
        <meta
          property="og:description"
          content="1 How to clear your Facebook apps cache on an iPhone to help the app run more efficiently"
        />
        <meta
          property="og:article:description"
          content="2 How to clear your Facebook apps cache on an iPhone to help the app run more efficiently"
        />
        <meta property="og:article:section" content="Some description." />
        <meta
          property="og:image:url"
          content="https://innercise-image-dev.s3.amazonaws.com/image/8ef30b41e9a6e5f187c71271b1921966dbe6cccffd985ec63fd4b0deb630d839-image-20221016102938-sm.png"
        />
      </MetaTags> */}
    </LandingProgramWrapper>
  );
};

export default LandingProgram;
