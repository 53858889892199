import React from "react"
import {
  StyledPhoneAnimateMain,
  StyledPhoneAnimateContent,
  StyledPhoneFrame,
} from "./PhoneAnimateMain.style"

const PhoneAnimateMain = () => {
  return (
    <StyledPhoneAnimateMain className="phone-animation">
      <StyledPhoneFrame />
      <StyledPhoneAnimateContent>
        <video
          className="iphone-main-video"
          src="/media/iphoneMain-animation.mp4"
          playsInline
          autoPlay
          muted
          loop
        ></video>
      </StyledPhoneAnimateContent>
    </StyledPhoneAnimateMain>
  )
}

export default PhoneAnimateMain
