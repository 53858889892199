import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import BodyContentContainer from "components/common/LayoutComponents/BodyContentContainer";
import { getClientRecommendation } from "services/recommendation";
import { verifyResponse } from "utils/httpRequest";
import {
  StyledRecommendationProgram,
  StyledRecommendationProgramContainer,
  StyledRecommendationProgramItem,
} from "./RecommendationProgram.style";

const RecommendationProgram = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [recommendedPrograms, setRecommendedPrograms] = useState([]);

  const getRecommendations = async () => {
    setLoading(true);
    const response = await getClientRecommendation();
    verifyResponse(response);
    setRecommendedPrograms(response.data.list);
    setLoading(false);
  };

  useEffect(() => {
    getRecommendations();
  }, []);

  return (
    <StyledRecommendationProgram>
      <BodyContentContainer>
        <h3>Recommendations</h3>
        <StyledRecommendationProgramContainer>
          {recommendedPrograms.map((program, programIndex) => {
            return (
              <StyledRecommendationProgramItem
                key={`recommended-program-${programIndex}`}
                onClick={() => {
                  navigate(`/program/${program.id}`);
                }}
              >
                <img src={program.thumbResourceDto.urlDto.imgUrl_SM} alt="" />
                <div className="title">{program.title}</div>
                <div className="category">{program.categoryName}</div>
              </StyledRecommendationProgramItem>
            );
          })}
        </StyledRecommendationProgramContainer>
      </BodyContentContainer>
    </StyledRecommendationProgram>
  );
};

export default RecommendationProgram;
