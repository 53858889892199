import React from "react"
import { QRCode } from "react-qrcode-logo"
import { StyledQrCode } from "./QrCode.style"

const QrCode = ({ codeValue, size }) => {
  const logoSize = size * 0.3
  return (
    <StyledQrCode className="qr-code">
      <QRCode
        value={codeValue}
        size={size}
        logoWidth={logoSize}
        logoHeight={logoSize}
        enableCORS={true}
        ecLevel="H"
        logoImage="/images/qr_code_logo.png"
      />
    </StyledQrCode>
  )
}

export default QrCode
