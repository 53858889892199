import React from "react"
import {
  StyledPhoneAnimateB,
  StyledPhoneAnimateContent,
  StyledPhoneFrame,
} from "./PhoneAnimateB.style"

const PhoneAnimateB = () => {
  return (
    <StyledPhoneAnimateB className="phone-animation">
      <StyledPhoneFrame />
      <StyledPhoneAnimateContent>
        <video
          src="/media/iphoneB-animation.mp4"
          playsInline
          autoPlay
          muted
          loop
        ></video>
      </StyledPhoneAnimateContent>
    </StyledPhoneAnimateB>
  )
}

export default PhoneAnimateB
