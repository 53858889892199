import React, { useEffect, useState } from "react"
import PageTitle from "components/common/PageTitle/PageTitle"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import FacebookIcon from "@mui/icons-material/Facebook"
import TwitterIcon from "@mui/icons-material/Twitter"
import InstagramIcon from "@mui/icons-material/Instagram"
import Tooltip from "@mui/material/Tooltip"
import Alert from "@mui/material/Alert"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import { getReferralCode } from "services/marketing"
import {
  WaitingListConfirmationWrapper,
  PageContent,
} from "./WaitingListConfirmation.style"

const WaitingListConfirmation = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const email = queryParams.get("hemail")
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const [isFail, setIsFail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lead, setLead] = useState(null)

  const [shareLink, setShareLink] = useState(
    `${window.location.protocol}//${window.location.host}/waitlist/share?referred=`
  )
  const [loaded, setLoaded] = useState(false)

  const checkReferralCode = async () => {
    try {
      setLoading(true)
      const response = await getReferralCode(email)
      setLead(response.data.lead)
      if (response.data.type === "fail") {
        setIsFail(true)
      }
      setLoaded(true)
    } catch (error) {
      setIsFail(true)
    } finally {
      setLoading(true)
    }
  }

  useEffect(() => {
    console.log("email: ", email)
    if (email) {
      checkReferralCode()
    }
  }, [])

  useEffect(() => {
    lead && setShareLink(`${shareLink}${lead.referralCode}`)
  }, [lead])

  return (
    <WaitingListConfirmationWrapper>
      <PageTitle title="Waitlist Confirmation Page" />
      <PageContent>
        <div className="logo">
          <img src="/images/innercise_logo_color.png" alt="logo" />
        </div>

        <div className="confirmation">
          {loaded &&
            (lead ? (
              <>
                <div className="greeting">
                  <CheckCircleOutlineIcon />
                  Congratulations, {lead.firstName}!
                </div>
                <p>Once we're ready to share, you'll be the first to know.</p>
                <div className="info">
                  <div className="heading">You are on the waiting list!</div>
                  <div>{lead.email}</div>
                </div>
              </>
            ) : (
              <div className="user-not-exist">
                {isFail ? "Service not available yet!" : "Email not exist"}
              </div>
            ))}

          {!loaded && loading && (
            <img
              src="/images/loading.gif"
              alt="loading icon"
              className="loading-icon"
            />
          )}

          {!email && !loading && (
            <Alert severity="warning">
              Invalid Url, please verify the url and try again!
            </Alert>
          )}
        </div>
      </PageContent>
      <div className="sharing">
        <PageContent className="content">
          <h1>Want to Win Up to $100,000 in Cash & Prizes?</h1>
          <div className="sub-heading">
            Get early access by referring your friends & family.
            <br />
            Every time you share, you get another entry to win!
          </div>

          <div className="share-box">
            <div className="copy-link-label">
              Copy link and share with friends!
            </div>
            <div className="input-group">
              <input type="text" value={lead ? shareLink : ""} />
              <ClickAwayListener
                onClickAway={() => {
                  setIsTooltipOpen(false)
                }}
              >
                <Tooltip
                  title="Copied"
                  onClose={() => {
                    setIsTooltipOpen(false)
                  }}
                  open={isTooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListenerP
                >
                  <div
                    className="btn"
                    onClick={() => {
                      lead && navigator.clipboard.writeText(shareLink)
                      setIsTooltipOpen(true)
                    }}
                  >
                    <ContentCopyIcon />
                    <span className="text">Copy Link</span>
                  </div>
                </Tooltip>
              </ClickAwayListener>
            </div>

            <div className="copy-link-label">OR send via</div>
            <div className="social-medias">
              <a
                target="_blank"
                rel="noreferrer"
                href={`http://twitter.com/share?text=MyInnercise.com coming&url=${encodeURIComponent(
                  shareLink
                )}`}
              >
                <div className="btn">
                  <TwitterIcon />
                  Twitter
                </div>
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  shareLink
                )}`}
              >
                <div className="btn">
                  <FacebookIcon />
                  Facebook
                </div>
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.instagram.com/?url=${encodeURIComponent(
                  shareLink
                )}`}
              >
                <div className="btn">
                  <InstagramIcon />
                  Instagram
                </div>
              </a>
            </div>
          </div>

          <div className="price-info">
            <div>
              <strong>Grand Prize</strong> - (5) 1-Hour Private Consulting
              Sessions with John Assaraf - $50,000
            </div>
            <div>
              <strong>Prize #2</strong> - (10) Winning the Game of Money Brain
              Training & Coaching Programs - $20,000
            </div>
            <div>
              <strong>Prize #3</strong> - (10) $1,000 Cash
            </div>
            <div>
              <strong>Prize #4</strong> - (50) Limited Edition Innercise
              T-Shirts
            </div>
            <div>
              <strong>Prize #5</strong> - (100) Limited Edition Innercise Coffee
              Mugs
            </div>
          </div>
        </PageContent>
      </div>
    </WaitingListConfirmationWrapper>
  )
}

export default WaitingListConfirmation
