const GlobalStyle = {
  mobileMaxWidth: "690px",
  tabletMaxWidth: "1114px",
  colors: {
    lightPurple: "#8246AF",
    darkPurple: "#6C3992",
    lightGreen: "#789C48",
    darkGreen: "#63823B",
    lightBlue: "#418FDE",
    darkGBlue: "#0071BB",
    lightOrange: "#FFA300",
    darkGOrange: "#DF8611",
    gray: "#707370",
    lightWhite: "#F8F8F8",
  },
}

export { GlobalStyle }
