import styled from "@emotion/styled"

const StyledPhoneAnimateMain = styled.div`
  display: flex;
  position: relative;
  aspect-ratio: 724/838;
  max-width: 560px;
  width: 100%;
  align-items: center;
  justify-content: center;
  transform: translateY(-35px);
`

const StyledPhoneFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-image: url("/images/phoneMain-frame.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 10;
`
const StyledPhoneAnimateContent = styled.div`
  position: absolute;
  width: 43.2%;
  top: 7.4%;
  left: 30%;
  display: flex;
  video.iphone-main-video {
    width: 100%;
  }
`

export { StyledPhoneAnimateMain, StyledPhoneFrame, StyledPhoneAnimateContent }
