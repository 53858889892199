import React from "react";
import styled from "@emotion/styled";

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 5rem;
  align-items: center;
`;
const Footer = () => {
  return <FooterWrapper>footer</FooterWrapper>;
};

export default Footer;
