import React from "react";
import CategoryDisplay from "./CategoryDisplay";
import DailyPrime from "components/common/DailyPrime";
import RecommendationProgram from "components/common/RecommendationProgram";
import ContinueListening from "components/common/ContinueListening";
import DiscoverNewProgram from "components/common/DiscoverNewProgram";
import { StyledDashboard } from "./Dashboard.style";

const Dashboard = () => {
  return (
    <StyledDashboard>
      <DailyPrime />
      <CategoryDisplay />
      <RecommendationProgram />
      <ContinueListening />
      <DiscoverNewProgram />
    </StyledDashboard>
  );
};

export default Dashboard;
