import React from "react";
import styled from "@emotion/styled";

const FullWidthContainerWrapper = styled.div`
  margin: 0 auto;
  color: #c9cace;
  background-color: #1e212a;
`;
const FullWidthContainer = (props) => {
  return (
    <FullWidthContainerWrapper>{props.children}</FullWidthContainerWrapper>
  );
};

export default FullWidthContainer;
