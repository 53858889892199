import styled from "@emotion/styled"
import { GlobalStyle } from "styles/global"

const mobileMaxWidth = GlobalStyle.mobileMaxWidth
const tabletMaxWidth = GlobalStyle.tabletMaxWidth

const WaitingListWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #6c3992;

  button {
    font-family: Aeonik !important;
    width: 255px;
    border: 0;
    border-radius: 0;
    background-color: #ffa300;
    color: black;
    font-weight: bold;
    line-height: 31.2px;
    font-size: 20px;
    padding: 18px 10px;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    cursor: pointer;
  }

  .logo {
    width: 220px;
  }
  .section-full {
    width: 100%;
  }

  .heading {
    font-family: Aeonik;
    font-weight: 700;
    font-size: 32px;
  }

  .sub-heading {
    font-family: Aeonik;
    font-style: normal;
    font-size: 32px;
    line-height: 120%;
    text-transform: capitalize;
  }

  .prompt {
    display: flex;

    font-weight: 500;
    font-size: 12px;
    line-height: 156%;
    align-items: center;
    color: #707370;
    img {
      width: 15px;
      height: 15px;
      margin-left: 2px;
      margin-right: 5px;
    }
  }

  .h1 {
    font-family: Aeonik;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 130%;
    /* or 48px */

    text-transform: capitalize;
  }
  ul {
    margin: 40px 0;
    list-style: none;
    list-style-type: none;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 166%;
  }

  p {
    font-family: Aeonik;
    font-weight: 400;
    font-size: 16px;
    line-height: 166%;
    .bold {
      font-family: Aeonik;
      font-weight: 700;
    }
  }

  .panel {
    display: flex;
    flex-direction: column;

    &.v-middle {
      justify-content: center;
    }

    &.left {
      width: 60%;
    }
    &.right {
      width: 40%;
    }
  }
`

const PageContent = styled.div`
  max-width: 1158px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
`

const WaitingListBanner = styled.div`
  body {
    background-color: red;
  }
  background-color: #8fb1d9;
  overflow: hidden;
  padding: 30px 0 20px 0;
  width: 100%;
  /* min-height: calc(100vh - 103px); */
  height: 817px;
  color: black;
  position: relative;

  @media (max-width: ${tabletMaxWidth}) {
    width: calc(100% - 50px);
    padding: 20px 25px 0px 25px;
    height: auto;
  }

  @media (max-width: ${mobileMaxWidth}) {
    .content {
      padding: 0;
    }
    .logo {
      width: 161px;
      margin: 0 auto;
      display: block;
    }

    .main-panel {
      margin-top: 35px !important;
      flex-direction: column !important;
    }

    .left-panel {
      width: 100% !important;
      input {
        padding: 7px 20px !important;
        height: 40px !important;
        font-size: 16px !important;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 156%;
        text-align: center;
      }
      .outline {
        font-size: 12px;
        text-align: center;
      }
      .input-row-wrapper {
        width: 100% !important;
        input {
          width: 50%;
        }
        #input-email {
          width: 100% !important;
        }
        button {
          display: none;
        }
      }

      .recommendation {
        display: flex;
        flex-direction: column;
        align-items: center;
        .rating,
        .person {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .desc {
          text-align: center;
          font-family: Aeonik;
          font-size: 16px;
        }
        .avatar {
          margin: 20px 0;
        }
      }
    }
    .right-panel {
      width: 100% !important;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;
    }

    .mobile-btn {
      display: block !important;
      line-height: 24px;
    }

    .right-mask-mobile {
      display: block !important;
      width: 90% !important;
      max-width: none;
    }
    .right-mask {
      display: none !important;
    }
  }

  .mobile-btn {
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin: 12px 0;
    display: none;
  }

  video.fullscreen {
    position: absolute;
    z-index: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &::-webkit-media-controls {
      display: none !important;
    }
  }

  @media (max-height: 845px) and (min-width: ${tabletMaxWidth}) {
    padding: 30px 0 0 0;
    min-height: auto;
    height: 689px;
  }

  @media (max-height: 753px) and (min-width: ${tabletMaxWidth}) {
    height: 650px;
  }

  @media (max-height: 712px) and (min-width: ${tabletMaxWidth}) {
    height: 579px;
  }

  @media (max-height: 660px) and (min-width: ${tabletMaxWidth}) {
    height: 566px;
  }

  .content {
    position: relative;
    z-index: 1;

    /* media query for small height screen */
    @media (max-height: 845px) and (min-width: ${tabletMaxWidth}) {
      transform: scale(0.8) translateY(-70px);
    }
    @media (max-height: 753px) and (min-width: ${tabletMaxWidth}) {
      transform: scale(0.7) translateY(-70px);
    }
    /* @media (max-height: 712px) and (min-width: ${tabletMaxWidth}) {
      transform: scale(0.65) translateY(-70px);
    } */
    @media (max-height: 712px) and (min-width: ${tabletMaxWidth}) {
      transform: scale(0.65) translateY(-150px);
    }
    @media (max-height: 660px) and (min-width: ${tabletMaxWidth}) {
      transform: scale(0.65) translateY(-170px);
    }

    /* media query for tablet view */
    @media (max-width: ${tabletMaxWidth}) {
      .main-panel {
        height: auto;
        flex-direction: column;

        .left-panel {
          width: calc(100% - 80px);
          display: flex;
          flex-direction: column;
          align-items: left;
          padding: 0 40px;
        }

        .right-panel {
          width: 100%;
          /* padding-bottom: 20px; */
          padding: 0;
          justify-content: center;
          .right-mask {
            position: inherit;
            margin-top: 70px;
          }
        }
      }
    }

    /* media query for mobile view */
    @media (max-width: ${mobileMaxWidth}) {
      h1 {
        font-family: Aeonik;
        font-style: normal;
        font-weight: 700;
        font-size: 27.5px;
        line-height: 130%;
        text-align: center;
        display: block;
      }
      h2 {
        display: none;
      }
      .panel {
        width: 100%;
      }

      .main-panel {
        height: auto;
      }

      .left-panel,
      .right-panel {
        padding: 0 !important;
      }

      .waiting-list-panel {
        .input-row-wrapper.has-icon {
          svg {
            top: 32% !important;
            height: 19px;
          }
        }
      }

      .right-panel {
        .right-mask {
          position: inherit;
          margin-top: 0;
        }
        .right-mask-mobile {
          margin-top: 40px;
        }
      }
    }
  }

  .main-panel {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    height: 100%;
  }

  h1 {
    display: none;
  }

  h2 {
    margin: 0;
    font-family: Aeonik;
    font-weight: 700;
    font-size: 60px;
    letter-spacing: -1px;
  }

  p {
    margin-top: 20px;
    margin-bottom: 45px;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 156%;
  }

  button {
    border-radius: 0;
  }

  .outline {
    font-family: Aeonik;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 156%;
  }

  .left-panel {
    width: 70%;
  }

  .right-panel {
    width: 30%;
    display: flex;
    justify-content: right;

    .right-mask-mobile {
      width: 343px;
      display: none;
    }
  }

  .right-mask {
    position: absolute;
    right: -133px;
    bottom: -80px;
    max-height: 755px;
  }

  .waiting-list-panel {
    margin-top: 30px;

    .message {
      height: 25px;
      font-size: 18px;
      line-height: 120%;
      padding: 5px 0px 0px 5px;
      color: #00000085;
      &.error {
        color: #e50909;
      }

      .loading-icon {
        display: flex;

        img {
          width: 60px;
          margin: -7px -6px 0 -17px;
        }
      }
    }

    input {
      background-color: white;
      color: black;
      border: 0;
      padding: 13px 20px;
      width: 247px;
      height: 46px;
      font-size: 20px;

      &.email-input {
        width: 249px;
      }

      &:focus {
        border: 0;
        outline: 0;
      }

      &.right-split-line {
        border-right: 1px solid gray;
      }

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: gray;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: gray;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: gray;
      }
    }

    .input-row-wrapper {
      border-radius: 12px;
      width: fit-content;
      overflow: hidden;
      margin: 12px 0;
      display: flex;
      input {
        border-radius: 0 !important;
      }

      &.has-icon {
        position: relative;
        svg {
          position: absolute;
          top: 32.6%;
          left: 20px;
          color: gray;
        }

        input {
          &:nth-of-type(1) {
            padding-left: 50px !important;
          }
        }
      }
    }
  }

  .recommendation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 5px;

    .rating {
      margin: 5px 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: center;
      .label {
        display: inline-block;
        margin-left: 10px;
      }
      svg {
        fill: orange;
      }
    }

    .person {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .avatar {
      margin-right: 20px;
      width: 64px;
      height: 64px;
    }

    .desc {
      font-size: 14px;
      width: 100%;
      line-height: 24px;
    }
  }
`
const LandingPageFooter = styled.div`
  display: flex;
  min-height: 190px;
  background-color: #6c3992;
  width: 100%;

  /* media query for small height screen */
  @media (max-height: 845px) and (min-width: ${tabletMaxWidth}) {
    .content {
      transform: scale(0.8);
    }
  }
  @media (max-height: 753px) and (min-width: ${tabletMaxWidth}) {
    .content {
      transform: scale(0.7);
    }
  }
  @media (max-height: 712px) and (min-width: ${tabletMaxWidth}) {
    .content {
      transform: scale(0.65);
    }
  }
  @media (max-height: 712px) and (min-width: ${tabletMaxWidth}) {
    .content {
      transform: scale(0.65);
    }
  }
  @media (max-height: 660px) and (min-width: ${tabletMaxWidth}) {
    .content {
      transform: scale(0.65);
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 180px;
    }

    .info-region {
      display: flex;
      align-items: center;

      .links {
        button {
          font-family: Aeonik;
          font-weight: 700;
          text-decoration: none;
          color: white;
          font-size: 16px;
          line-height: 120%;
          margin-left: 20px;
          padding: 5px;
          width: fit-content;
          background-color: transparent;
          box-shadow: none;

          &:hover {
            color: #e1d0d0;
          }
        }
      }
      img {
        width: 155px;
        margin-left: 40px;
      }
    }
  }

  @media (max-width: ${mobileMaxWidth}) {
    .content {
      width: 100%;
      margin: 35px 0;
      justify-content: space-between;
      flex-direction: column-reverse;

      .logo {
        margin-top: 25px;
      }

      .info-region {
        flex-direction: column;
        align-items: center;
        height: 100px;
        display: flex;
        justify-content: center;
        img {
          margin-left: 0;
          margin-top: 20px;
        }
        .links {
          button {
            display: block;
            margin-bottom: 5px;
            margin-left: 0;
          }
        }
      }
    }
  }
`

export { WaitingListWrapper, WaitingListBanner, LandingPageFooter, PageContent }
