import styled from "@emotion/styled"
import { GlobalStyle } from "styles/global"

const mobileMaxWidth = GlobalStyle.mobileMaxWidth
const tabletMaxWidth = GlobalStyle.tabletMaxWidth

const PageContent = styled.div`
  max-width: 1158px;
  width: calc(100% - 40px);
  margin: 0 auto;
  @media (max-width: ${mobileMaxWidth}) {
    width: calc(100% - 32px);
    p {
      font-size: 16px;
      line-height: 120%;
    }
  }
`

const NurturePageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .mobile-only {
    display: none;
  }
  .mobile-not {
    display: inline-block;
  }

  button {
    width: 255px;
    height: 64px;
    border: 0;
    border-radius: 0;
    background-color: #ffa300;
    color: black;
    font-weight: bold;
    line-height: 31.2px;
    font-size: 20px;
    padding: 18px 10px;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    cursor: pointer;
  }

  .logo {
    width: 100%;
    text-align: center;
    padding: 12px 0;
    border-bottom: 1px solid #ffffff26;
    img {
      width: 170px;
    }
  }

  .video-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: ${(props) =>
      props.target === "waitlist" ? GlobalStyle.colors.lightPurple : "#0659cb"};
    color: white;
    /* height: 590px; */
    margin-bottom: 200px;

    .video-content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 350px;

      .title {
        font-family: Aeonik;
        font-style: normal;
        text-align: center;
        font-weight: 700;
        font-size: 43px;
        line-height: 140%;
        margin-top: 70px;
      }

      .audio-player {
        position: absolute;
        top: 80%;
        margin-top: 72px;
        max-width: 800px;
        pointer-events: all;
      }
    }
  }

  .desc-content {
    margin: 30px 0 80px 0px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .footer {
    min-height: 100px;
    margin-bottom: 100px;
    display: flex;
    width: 100%;
    box-shadow: 0px -1px 0px rgba(7, 7, 7, 0.1);

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .help-contact {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        color: #828282;
        strong {
          color: #1f1f1f;
          opacity: 0.8;
          margin-right: 5px;
        }

        .question-sign {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      position: relative;

      .center {
        position: absolute;
        left: calc(50% - 130px);
      }

      .links {
        a {
          color: black;
          font-size: 14px;
          text-decoration: none;
        }
      }
      svg {
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
      img {
        width: 161px;
        height: 47px;
      }
    }
  }

  @media (max-width: ${mobileMaxWidth}) {
    .mobile-only {
      display: block;
    }
    .mobile-not {
      display: none;
    }
    button {
      width: 100%;
      font-size: 18px;
    }

    .video-section {
      height: 470px;
      margin-bottom: 140px;
      .video-content {
        text-align: center;

        .title {
          font-size: 18px;
          line-height: 140%;
          margin-top: 42px;
        }

        .audio-player {
          width: 100%;
          margin-top: 42px;
        }
        /* padding: 0 20px; */
      }
    }

    .desc-content {
      min-height: auto;
    }

    .footer {
      flex-direction: column;
      .content {
        display: flex;
        flex-direction: column;

        .center {
          position: initial;
        }

        .help-contact {
          display: flex;
          flex-direction: column;
          .question-sign {
            margin: 20px 0 15px 0px;
          }
        }

        .links {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 20px 0 35px 0px;
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 585px) {
    .video-section {
      height: 420px;
    }
  }

  @media (max-width: 510px) {
    .video-section {
      height: 380px;
    }
  }

  @media (max-width: 450px) {
    .video-section {
      height: 350px;
    }
  }

  @media (max-width: 420px) {
    .video-section {
      height: 320px;
    }
  }

  @media (max-width: 358px) {
    .video-section {
      height: 320px;
      .desc-content {
        margin: 0 0 70px 0;
      }
    }
  }
`

export { NurturePageWrapper, PageContent }
