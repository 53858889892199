import React, { useState, useEffect, useRef } from "react"
import PageTitle from "components/common/PageTitle/PageTitle"
import { useNavigate } from "react-router-dom"
import StarOutlinedIcon from "@mui/icons-material/StarOutlined"
import TestimonialSlider from "components/common/TestimonialSlider"
import AudioPlayer from "components/common/AudioPlayer"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import DraftsIcon from "@mui/icons-material/Drafts"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import { validateEmail } from "utils/validation"
import { addActionToSpreadSheet } from "services/marketing"
import { verifyResponse } from "utils/httpRequest"
import { audioPlayList } from "./data/audio"
import { questions } from "./data/question"
import { experts, testimonial } from "./data/testimonial"
import PhoneAnimateMain from "./PhoneAnimateMain"
import PhoneAnimateA from "./PhoneAnimateA"
import PhoneAnimateB from "./PhoneAnimateB"
import PhoneAnimateC from "./PhoneAnimateC"
import {
  LandingPageWrapper,
  LadingPageBanner,
  LandingCompaniesStory,
  PageContent,
  LadingAppIntro,
  LandingTeam,
  LandingAudioTrail,
  LandingExperts,
  LandingTestimonial,
  LandingPhonePreview,
  LandingPageQuestions,
  LandingPageFooter,
} from "./LandingPage.style"

const LandingPage = () => {
  const [playAudio, setPlayAudio] = useState(audioPlayList[0])
  const [playAudioIndex, setPlayAudioIndex] = useState(0)
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const navigate = useNavigate()

  // get url params variables
  const queryParams = new URLSearchParams(window.location.search)
  const utmMedium = queryParams.get("utm_medium")
  const utmSource = queryParams.get("utm_source")
  const utmCampaign = queryParams.get("utm_campaign")
  const utmContent = queryParams.get("utm_content")
  const utmAffiliate = queryParams.get("utm_affiliate")

  const formRef = useRef()
  const emailRef = useRef("")
  const nameRef = useRef("")

  const selectAudio = (audio) => {
    setPlayAudio(audio)
    setPlayAudioIndex(audio.index)
  }

  const validate = () => {
    let isValid = false
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === ""
    ) {
      setErrorMessage("* All fields are required.")
    } else if (!validateEmail(email.trim())) {
      setErrorMessage("* please input valid email address.")
    } else {
      setErrorMessage("")
      isValid = true
    }
    return isValid
  }

  const redirectConfirmationPage = () => {
    const forwardLink = `/download/confirmation?email=${emailRef.current}&name=${nameRef.current}`
    console.log("forwardLink: ", forwardLink)
    setTimeout(() => {
      navigate(forwardLink)
    }, 1000)
  }

  const addRecordGoogleSheet = async ({
    action,
    firstName,
    lastName,
    email,
    utmMedium,
    utmSource,
    utmAffiliate,
    utmCampaign,
    utmContent,
  }) => {
    try {
      const response = await addActionToSpreadSheet({
        action,
        first: firstName,
        last: lastName,
        email,
        utm_medium: utmMedium,
        utm_source: utmSource,
        utm_affiliate: utmAffiliate,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
      })
      verifyResponse(response)
    } catch (err) {
      console.error("error: ", err)
    }
  }

  const initMarketo = () => {
    window.MktoForms2.loadForm(
      "//join.myneurogym.com",
      "519-VOW-812",
      1142,
      (form) => {
        form.onSubmit(() => {
          setFormSubmitted(true)
        })
        form.onSuccess(function (values, followUpUrl) {
          // On Success
          console.log("Success Submit Marketo")
          form.getFormElem().hide()
          // Redirect after processing on your side to confirmation page
          redirectConfirmationPage()
          return false
        })
        formRef.current = form
      }
    )
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    // init Marketo
    initMarketo()
  }, [])

  const submitAction = async () => {
    setSubmitted(true)
    // valid input
    if (!validate()) {
      return false
    }

    // If Marketo Form is Ready, then submit Marketo Form
    formRef.current.setValues({
      FirstName: firstName,
      LastName: lastName,
      Email: email,
    })
    console.log("Marketo Ready to Submit", formRef.current.vals())

    // sync to google sheet
    await addRecordGoogleSheet({
      action: "web-download",
      firstName,
      lastName,
      email,
      utmMedium: utmMedium || "",
      utmSource: utmSource || "",
      utmAffiliate: utmAffiliate || "",
      utmCampaign: utmCampaign || "",
      utmContent: utmContent || "",
    })

    // submit to Marketo database
    formRef.current.submit()
  }

  return (
    <LandingPageWrapper>
      <PageTitle title="Landing Page" />
      <LadingPageBanner>
        <video
          className="fullscreen"
          src="/media/landing-bg-video-720.mp4"
          playsInline
          autoPlay
          muted
          loop
        ></video>
        <PageContent className="content">
          <img className="logo" src="/images/Innercise_logo.png" alt="logo" />
          <div className="main-panel">
            <div className="left-panel">
              <h2 className="non-mobile-content">
                Imagine A Mindset Coach <br className="show-all-the-time" />
                In The Palm Of Your Hand
              </h2>

              <h2 className="mobile-content">
                A Mindset Coach In <br />
                The Palm Of Your Hand
              </h2>

              <p className="non-mobile-content">
                Download the Innercise™ App Now to Get Instant Access to 500+
                Fully Guided Visualizations, Meditations, Affirmations, &
                High-Performance Brain Training Sessions
              </p>

              <p className="mobile-content">
                Get Access to 500+ Fully Guided Visualizations, Meditations,
                Affirmations, & High-Performance Brain Training Sessions
              </p>

              <div className="outline non-mobile-content">
                Backed by Cutting-Edge Science & 10 Years of Brain Research
              </div>

              <div className="outline mobile-content">
                *Backed by Cutting-Edge Science & 10 Years of Brain Research
              </div>

              <div className="waiting-list-panel">
                <div className="input-row-wrapper">
                  <input
                    id="input-first-name"
                    placeholder="First name*"
                    className="right-split-line"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                      nameRef.current = e.target.value
                    }}
                  />
                  <input
                    id="input-last-name"
                    placeholder="Last name*"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value)
                    }}
                  />
                </div>
                <div className="input-row-wrapper has-icon">
                  <DraftsIcon />
                  <input
                    id="input-email"
                    placeholder="Your email address*"
                    className="email-input"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                      emailRef.current = e.target.value
                    }}
                  />

                  <button
                    className="submit-btn"
                    onClick={() => {
                      submitAction()
                    }}
                  >
                    Join Now for Free
                  </button>
                </div>
                <button
                  className="submit-btn-mobile"
                  onClick={() => {
                    submitAction()
                  }}
                >
                  Join Now for Free
                </button>
                <div className="prompt">
                  <img src="/images/shield.png" alt="shield icon" />
                  We will not spam, rent, or sell your information.
                </div>
                <div
                  className={`message ${
                    submitted && errorMessage !== "" && "error"
                  }`}
                >
                  {submitted && errorMessage}
                  {formSubmitted && (
                    <>
                      <div className="loading-icon">
                        <img src="/images/loading.gif" alt="loading icon" />
                        {"Data submitted, waiting for redirect ..."}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="recommendation">
                <img
                  src="/images/person-1.png"
                  alt="person"
                  className="avatar"
                />
                <div className="desc">
                  “Innercises™ are cutting-edge techniques to maximize your
                  fullest potential.” <br />
                  <i>- Harvard Psychiatrist - Dr. Srini Pillay</i>
                </div>
              </div>
            </div>

            <div className="right-panel">
              <img src="/images/iphone.png" alt="phone" />
            </div>
          </div>
          <div className="recommendation tablet">
            <img src="/images/person-1.png" alt="person" className="avatar" />
            <div className="desc">
              “Innercises™ are cutting-edge techniques to maximize your fullest
              potential.” <br />
              <i>- Harvard Psychiatrist - Dr. Srini Pillay</i>
            </div>
          </div>
        </PageContent>
      </LadingPageBanner>

      <LandingCompaniesStory>
        <div className="info">
          <div className="heading">Innercise ®</div>
          <div className="desc">
            The #1 mindset coaching & mental fitness training app
          </div>
          <div className="rating">
            <div className="stars">
              <StarOutlinedIcon />
              <StarOutlinedIcon />
              <StarOutlinedIcon />
              <StarOutlinedIcon />
              <StarOutlinedIcon />
            </div>
            <div className="label">Over 100,000 success stories</div>
          </div>
          <div className="companies">
            <div className="msg">
              Innercise™ Founder John Assaraf Has Been Seen On:
            </div>
            <img src="/images/companies.jpg" alt="companies" />
          </div>
        </div>
      </LandingCompaniesStory>

      <LadingAppIntro>
        <PageContent className="content">
          <div className="panel left">
            <div className="heading">Get Unlimited Access to 500+</div>
            <div className="sub-heading">
              Guided Visualizations, Meditations, & High-Performance Brain
              Training Sessions So You:
            </div>
            <ul>
              <li>
                <img src="/images/check-double-line.png" alt="check point" />
                Achieve any goal faster
              </li>
              <li>
                <img src="/images/check-double-line.png" alt="check point" />
                Boost self-confidence
              </li>
              <li>
                <img src="/images/check-double-line.png" alt="check point" />
                Release anxiety
              </li>
              <li>
                <img src="/images/check-double-line.png" alt="check point" />
                Stop procrastinating
              </li>
              <li>
                <img src="/images/check-double-line.png" alt="check point" />
                Eliminate limiting beliefs
              </li>
              <li>
                <img src="/images/check-double-line.png" alt="check point" />
                Live life on your terms!
              </li>
            </ul>
            <p>
              Backed by cutting-edge science & 10 years of research working with
              top Brain Experts and Psychologists from Harvard, Oxford,
              Pepperdine, & more.
            </p>

            <button
              onClick={() => {
                scrollToTop()
              }}
            >
              Join Now for Free
            </button>
          </div>
          <div className="panel right h-middle">
            <PhoneAnimateMain />
          </div>
        </PageContent>
      </LadingAppIntro>

      <LandingAudioTrail>
        <PageContent className="content">
          <div className="h1">Try Innercise® For Yourself</div>
          <div className="audio-preview-section">
            <div className="audio-taps">
              <ul>
                {audioPlayList.map((audio, index) => (
                  <li
                    key={`audio-tap-${index}`}
                    className={`${
                      playAudioIndex === audio.index ? "selected" : ""
                    }`}
                    onClick={() => {
                      selectAudio(audio)
                    }}
                  >
                    {audio.title}
                  </li>
                ))}
              </ul>
            </div>
            <p dangerouslySetInnerHTML={{ __html: playAudio.desc }} />

            <AudioPlayer url={playAudio.url} title={playAudio.audioTitle} />
          </div>
        </PageContent>
      </LandingAudioTrail>

      <LandingTeam>
        <PageContent>
          <div className="h1 non-desktop">
            Start Living Life
            <br className="mobile-only" /> On Your Own Terms
          </div>
        </PageContent>
        <div className="content">
          <PageContent>
            <div className="section col">
              <div className="h1 desktop-only">
                Start Living Life On Your Own Terms
              </div>
              <div className="section-row">
                <div className="panel left  v-middle">
                  <PhoneAnimateA className="phone-a" />
                </div>
                <div className="panel right v-middle achieve-any-section">
                  <div className="heading">
                    Achieve Any Goal Faster & Easier
                  </div>
                  <ul>
                    <li>
                      Discover{" "}
                      <strong>
                        500+ guided visualizations, meditations, affirmations,
                        and high-performance brain training sessions
                      </strong>
                    </li>
                    <li>
                      Personalized recommendations based on{" "}
                      <strong>your goals and unique preferences</strong>
                    </li>
                    <li>
                      Unlock more{" "}
                      <strong>self-confidence and more drive</strong> as you
                      master your mindset (say goodbye to self-sabotage and
                      procrastination right now)
                    </li>
                    <li>
                      Get access to short, medium, and long fully guided
                      sessions{" "}
                      <strong>
                        so you always stay on the fast track to success
                      </strong>
                    </li>
                  </ul>
                  <button
                    onClick={() => {
                      scrollToTop()
                    }}
                  >
                    Join The Innercise® Revolution
                  </button>
                </div>
              </div>
            </div>
          </PageContent>
        </div>
        <div className="content alt">
          <PageContent>
            <div className="section">
              <div className="panel left v-middle release-stress-section">
                <div className="heading">
                  Release Stress And Use
                  <br /> Fear As Fuel For Success
                </div>
                <ul>
                  <li>
                    Unlock proven techniques to{" "}
                    <strong>
                      release stress, relieve anxiety, and overcome overwhelm
                    </strong>
                  </li>
                  <li>
                    Leverage the latest brain science breakthroughs to{" "}
                    <strong>
                      let go of the limiting beliefs and subconscious blocks
                    </strong>{" "}
                    that hold you back from your fullest power & potential
                  </li>
                  <li>
                    Learn how to{" "}
                    <strong>turn fear into your fuel for success</strong> using
                    proven evidence-based methods
                  </li>
                </ul>
              </div>
              <div className="panel right v-middle">
                <PhoneAnimateB />
              </div>
            </div>
          </PageContent>
        </div>
        <div className="content">
          <PageContent>
            <div className="section">
              <div className="panel left v-middle">
                <PhoneAnimateC />
              </div>
              <div className="panel right v-middle improve-focus-section">
                <div className="heading">Improve Focus And Productivity</div>
                <ul>
                  <li>
                    Rewire to “re-fire” your brain with cutting-edge techniques
                    to{" "}
                    <strong>quickly boost your focus and productivity</strong>
                  </li>
                  <li>
                    Overcome the mental hurdles that harm your ability to
                    concentrate on any given task (
                    <strong>
                      & unlock deep focus required for peak performance
                    </strong>
                    )
                  </li>
                  <li>
                    Calm your mind so you{" "}
                    <strong>
                      work faster, perform better, and become more efficient and
                      effective
                    </strong>
                  </li>
                </ul>
                <button
                  onClick={() => {
                    scrollToTop()
                  }}
                >
                  Join Now For Free
                </button>
              </div>
            </div>
          </PageContent>
        </div>
      </LandingTeam>

      <LandingExperts>
        <div className="h1">Meet The Experts</div>
        <TestimonialSlider
          data={experts}
          time={120}
          imageWidth={400}
          fit="contain"
          imageMargin={"0 4px"}
        />
      </LandingExperts>

      <LandingTestimonial>
        <div className="panel left">
          <div className="info-box">
            <div className="h1">
              Over 100,000 Success Stories.
              <br className="mobile-only" /> We want you to Be Next.
            </div>
            <p>
              Start your free trial today and experience why over 100,000
              members across the globe have used Innercise to finally unlock
              their fullest power and potential.
            </p>
          </div>
        </div>
        <div className="panel right">
          <TestimonialSlider
            data={testimonial}
            time={120}
            imageWidth={400}
            fit="contain"
          />
        </div>
      </LandingTestimonial>

      <LandingPhonePreview>
        <PageContent className="content">
          <div className="h1">
            Join the #1 Mindset Coaching & <br /> Mental Fitness Training App
            (FREE!){" "}
          </div>
          <div className="login-panel">
            <div className="input-group-name">
              <input
                type="text"
                placeholder="First Name*"
                className="has-right-border"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value)
                }}
              />
              <div className="separator" />
              <input
                type="text"
                placeholder="Last Name*"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                }}
              />
            </div>

            <div className="input-wrapper has-icon input-text-email">
              <MailOutlineIcon />
              <input
                type="text"
                placeholder="Your email address*"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </div>
            <button
              onClick={() => {
                submitAction()
              }}
            >
              Join Now for Free
            </button>
          </div>
          <div className="prompt">
            <img src="/images/shield.png" alt="shield icon" />
            We will not spam, rent, or sell your information.
          </div>

          <div
            className={`message ${submitted && errorMessage !== "" && "error"}`}
          >
            {submitted && errorMessage}
            {formSubmitted && (
              <>
                <div className="loading-icon">
                  <img src="/images/loading.gif" alt="loading icon" />
                  {"Data submitted, waiting for redirect ..."}
                </div>
              </>
            )}
          </div>
        </PageContent>
        <img
          src="/images/iphone-preview.png"
          alt="app preview"
          className="preview-img"
        />
      </LandingPhonePreview>

      <LandingPageQuestions id="faq">
        <PageContent className="content">
          <div className="h1">Have Any Questions?</div>
          <p>
            We're here to support you every step of the way. Let's do this -
            together.
          </p>
          {questions.map((question, index) => {
            return (
              <div
                key={`question-item-${index}`}
                className={`question ${
                  selectedQuestionIndex === index + 1 ? "expending" : ""
                }`}
                onClick={() => {
                  selectedQuestionIndex === index + 1
                    ? setSelectedQuestionIndex(0)
                    : setSelectedQuestionIndex(index + 1)
                }}
              >
                <div className="title">
                  <div>{question.title}</div>
                  <ExpandMoreIcon />
                </div>
                <div className="answer">
                  <div dangerouslySetInnerHTML={{ __html: question.answer }} />
                </div>
              </div>
            )
          })}
        </PageContent>
      </LandingPageQuestions>

      <LandingPageFooter>
        <PageContent className="content">
          <img
            className="logo"
            src="/images/Innercise_logo.png"
            alt="innercise white logo"
          />
          <div className="info-region">
            <div className="links">
              <button
                onClick={() => {
                  console.log("go to terms and condition")
                  navigate("/terms")
                }}
              >
                Terms of Use
              </button>
              <button
                onClick={() => {
                  navigate("/policy")
                }}
              >
                Privacy Policy
              </button>
            </div>
            <img src="/images/app-store.png" alt="apple store logo" />
          </div>
        </PageContent>
      </LandingPageFooter>
    </LandingPageWrapper>
  )
}

export default LandingPage
