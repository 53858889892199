import React, { useState, useEffect, useRef } from "react"
import ReactPlayer from "react-player/lazy"
import PlayCircleIcon from "@mui/icons-material/PlayCircle"
import PauseCircleIcon from "@mui/icons-material/PauseCircle"
import { UseToggleDelay } from "components/customHooks"
import { VideoPlayerWrapper } from "./VideoPlayer.style"

const VideoPlayer = ({ url, thumbnail }) => {
  const [playing, setPlaying] = useState(false)
  const [playTime, setPlayTime] = useState(0)
  const [playStarted, setPlayStarted] = useState(false)
  const [duration, setDuration] = useState(0)
  const [progressRate, setProgressRate] = useState(0)
  const [movingEffect, setMovingEffect] = useState(false)

  const playerRef = useRef(null)
  const showSettingPenalRef = useRef(false)

  const controlPanelTrigger = UseToggleDelay(() => {
    setMovingEffect(false)
    showSettingPenalRef.current = false
  }, 4000)

  const onPlayerMouseMoving = () => {
    setMovingEffect(true)
    showSettingPenalRef.current = true
    controlPanelTrigger()
  }

  const onPlayButtonAction = () => {
    setPlayStarted(true)
    setPlaying(playing ? false : true)
  }

  const seekTo = (e) => {
    const rect = e.target.getBoundingClientRect()
    const width = rect.right - rect.left
    const xPosition = e.clientX - rect.left //x position within the element.
    const progressRate = xPosition / width
    playerRef.current.seekTo(duration * progressRate, "seconds")
  }

  useEffect(() => {
    setPlayTime(0)
  }, [url])

  return (
    <VideoPlayerWrapper
      className="audio-player"
      onMouseMove={onPlayerMouseMoving}
      onMouseLeave={() => {
        setMovingEffect(false)
        showSettingPenalRef.current = false
      }}
    >
      <ReactPlayer
        ref={playerRef}
        url={url}
        playing={playing}
        className="player"
        width="100%"
        height="100%"
        onProgress={(info) => {
          setPlayTime(info.loadedSeconds)
          setProgressRate(
            info.playedSeconds / duration > 1
              ? 1
              : info.playedSeconds / duration
          )
        }}
        onReady={(e) => {
          setDuration(playerRef.current.getDuration())
        }}
      />

      <div
        className={`over-layer ${
          (showSettingPenalRef.current || !playing) && "show"
        }`}
      >
        {thumbnail && !playStarted && (
          <img className="thumb-img" src={thumbnail} alt="thumb-cover" />
        )}

        {playing ? (
          <PauseCircleIcon className="play-btn" onClick={onPlayButtonAction} />
        ) : (
          <PlayCircleIcon className="play-btn" onClick={onPlayButtonAction} />
        )}
      </div>
    </VideoPlayerWrapper>
  )
}

export default VideoPlayer
