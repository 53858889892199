import styled from "styled-components"

const TestimonialSliderWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 300px;
  overflow-y: scroll;

  .gallery {
    --d: ${(props) => (props.time ? props.time + "s" : "10s")}; /* duration */
    --num-of-image: ${(props) =>
      props.imageCount ? props.imageCount : 10}; /* the length of image list  */
    --img-width: ${(props) =>
      props.imgWidth
        ? props.imgWidth + "px"
        : "250px"}; /* width of each image item */
    overflow: hidden;
    width: calc(var(--img-width) * var(--num-of-image) * 2);
  }

  .gallery > img {
    /* grid-area: 1/1; */
    width: var(--img-width);
    object-fit: ${(props) => (props.fit ? props.fit : "none")};
    /* height: 300px; */
    /* aspect-ratio: 1.5; */
    margin: ${(props) => (props.imageMargin ? props.imageMargin : "0")};
    animation: translateinfinite var(--d) linear infinite;
    cursor: pointer;
  }

  @keyframes translateinfinite {
    100% {
      transform: translateX(calc(-1 * var(--img-width) * var(--num-of-image)));
    }
  }

  &:hover {
    .gallery > img {
      animation-play-state: paused;
    }
  }
`
const TestimonialSliderImageRow = styled.div`
  position: absolute;
  display: inline-block;
  height: 100%;
`
const TestimonialSliderImageItem = styled.img`
  display: inline-flex;
  height: 100%;
`

export {
  TestimonialSliderWrapper,
  TestimonialSliderImageRow,
  TestimonialSliderImageItem,
}
