import styled from "@emotion/styled"

const ResetPasswordSuccessWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  width: calc(100% - 40px);
  margin-top: -40px;

  .logo {
    margin: 20px 0;
    img {
      width: 200px;
    }
  }

  .sub-title {
    font-size: 17px;
    margin-top: -15px;
    font-weight: 500;
  }

  .content {
    margin-top: 80px;
    text-align: center;

    .title {
      font-size: 29px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .msg {
      font-weight: 300;
      margin: 3px 0 0px 0px;
    }
  }
`
export { ResetPasswordSuccessWrapper }
