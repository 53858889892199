import styled from "@emotion/styled";

const ContentWidth = "1024px";

const BodyContentContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${ContentWidth};
  margin: 0 auto;
  /* background-color: #efefef; */
  @media (max-width: ${ContentWidth}) {
    width: 100%;
  }
`;

export { BodyContentContainerWrapper };
