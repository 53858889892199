import styled from "styled-components"
import { GlobalStyle } from "styles/global"

const mobileMaxWidth = "430px"
const tabletMaxWidth = "730px"

const WaitingListConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .logo {
    img {
      width: 220px;
      margin-top: 20px;
    }
  }

  .confirmation {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 200px;

    align-items: center;
    justify-content: center;

    .loading-icon {
      width: 130px;
    }

    .user-not-exist {
      font-family: Aeonik;
      font-weight: 700;
      font-size: 20px;
    }

    svg {
      color: ${GlobalStyle.colors.lightGreen};
      margin-right: 7px;
    }

    p {
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      padding: 0;
      margin: 10px 0;
    }
    .greeting {
      display: flex;
      color: ${GlobalStyle.colors.lightGreen};
      font-family: Aeonik;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
    }

    .info {
      display: flex;
      flex-direction: column;
      border: 1px solid #707370;
      border-radius: 4px;
      padding: 10px 40px;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      .heading {
        font-family: Aeonik;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
      }
    }
  }

  .sharing {
    background-color: #6c3992;
    min-height: calc(100vh - 280px);
    color: white;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    h1 {
      font-family: Aeonik;
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 120%;
      letter-spacing: -1px;
      text-align: center;
      margin: 25px 0 10px 0;
    }

    .sub-heading {
      font-family: Aeonik;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      text-align: center;
    }

    .price-info {
      margin-top: 60px;
      margin-bottom: 40px;
      max-width: 800px;
      font-size: 20px;
      line-height: 150%;
      text-align: center;

      > div {
        margin: 5px 0r;
      }
    }

    .share-box {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      max-width: 570px;
      width: 100%;
      align-items: flex-start;

      .copy-link-label {
        font-family: Aeonik;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        margin-bottom: 8px;
      }

      .input-group {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        input {
          width: calc(100% - 40px);
          height: 36px;
          padding: 15px 20px;
          border-radius: 8px;
          border: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          &:focus {
            outline: none;
          }
        }
        .btn {
          background-color: rgb(108, 58, 146);
          width: 105px;
          height: 62%;
          position: absolute;
          right: 20px;
          top: 20%;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          font-family: Aeonik;
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          border-radius: 8px;
          cursor: pointer;
          -webkit-user-select: none; /* Safari */
          -ms-user-select: none; /* IE 10 and IE 11 */
          user-select: none; /* Standard syntax */
        }
      }

      .social-medias {
        a {
          text-decoration: none;
          color: rgb(108, 58, 146);
        }
        .btn {
          background-color: white;
          color: rgb(108, 58, 146);
          height: 45px;
          margin-right: 10px;
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          font-family: Aeonik;
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          border-radius: 4px;
          cursor: pointer;
          -webkit-user-select: none; /* Safari */
          -ms-user-select: none; /* IE 10 and IE 11 */
          user-select: none; /* Standard syntax */
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  @media (max-width: ${tabletMaxWidth}) {
    .sharing {
      h1 {
        font-size: 40px;
      }
      .sub-heading {
        font-family: Aeonik;
        font-size: 18px;
        font-weight: 400;
      }

      .share-box {
        .input-group {
          input {
            height: 25px;
            font-size: 16px;
          }
          .btn {
            right: 10px;
            width: fit-content;
            .text {
              display: none;
            }
          }
        }

        .social-medias {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: ${mobileMaxWidth}) {
    .logo {
      text-align: center;
    }

    .confirmation {
      .info {
        width: calc(100% - 80px);
        word-wrap: break-word;
      }
    }

    .sharing {
      h1 {
        font-size: 32px;
      }
      .sub-heading {
        font-family: Aeonik;
        font-size: 16px;
        font-weight: 400;
      }

      .share-box {
        .input-group {
          input {
            height: 25px;
            font-size: 16px;
          }
          .btn {
            right: 10px;
            width: fit-content;
            .text {
              display: none;
            }
          }
        }
        .social-medias {
          .btn {
            width: calc(100% - 20px);
            margin: 10px 0;
            justify-content: center;
          }
        }
      }
      .price-info {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: left;
      }
    }
  }
`
const PageContent = styled.div`
  max-width: 1258px;
  width: calc(100% - 40px);
  padding: 0 20px;
  margin: 0 auto;
  @media (max-width: ${mobileMaxWidth}) {
  }
`

export { PageContent, WaitingListConfirmationWrapper }
