import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BodyContentContainer from "components/common/LayoutComponents/BodyContentContainer";
import { getProgramBySubCategoryId } from "services/program";
import { verifyResponse } from "utils/httpRequest";
import {
  StyledCategoryDisplay,
  StyledSubCategoryTitle,
  StyledProgramContainer,
  StyledProgramFrame,
} from "./CategoryDisplay.style";

const CategoryDisplay = () => {
  const [programs, setPrograms] = useState([]);
  let navigate = useNavigate();

  const selectedCategoryId = useSelector(
    (state) => state.category.selectedCategoryId
  );

  const loadSubCategory = async (subCategoryId) => {
    const response = await getProgramBySubCategoryId(subCategoryId);
    verifyResponse(response);
    setPrograms(response.data);
  };

  useEffect(() => {
    loadSubCategory(selectedCategoryId);
  }, [selectedCategoryId]);

  return (
    <StyledCategoryDisplay>
      <BodyContentContainer>
        {programs &&
          programs.list &&
          programs.list.map((subCategory, index) => {
            return (
              <div key={`subCategory-item-${index}`}>
                <StyledSubCategoryTitle>
                  {subCategory.name}
                </StyledSubCategoryTitle>
                <StyledProgramContainer>
                  {subCategory.programList.map((program, programId) => (
                    <StyledProgramFrame
                      key={`program-item-${programId}`}
                      onClick={() => {
                        navigate(`/program/${program.id}`);
                      }}
                    >
                      <img
                        src={program.thumbResourceDto.urlDto.imgUrl_XS}
                        alt=""
                      />
                      <div className="program-name">{program.title}</div>
                    </StyledProgramFrame>
                  ))}
                </StyledProgramContainer>
              </div>
            );
          })}
      </BodyContentContainer>
    </StyledCategoryDisplay>
  );
};

export default CategoryDisplay;
