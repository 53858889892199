import styled from "@emotion/styled";

const StyledDailyPrimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e6cece;
  height: 450px;
  margin-bottom: 250px;
`;

const StyledDailyPrimeContainer = styled.div`
  padding: 15px;
  /* border: 1px solid #ccc; */
  margin: 40px 0;
  background-color: #f1eef3;
  border-radius: 5px;
  height: 100%;
  width: auto;
  img {
    width: 100%;
  }
`;

export { StyledDailyPrimeWrapper, StyledDailyPrimeContainer };
