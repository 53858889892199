import styled from "styled-components"
import { GlobalStyle } from "styles/global"

const mobileMaxWidth = GlobalStyle.mobileMaxWidth

const AudioPlayerWrapper = styled.div`
  width: 100%;
  display: flex;
`
const AudioPlayerControlPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: 105px;
  width: calc(100% - 60px);
  padding: 0 30px;
  background: rgba(255, 255, 255, 0.37);
  border-radius: 20px;
  justify-content: center;

  .title {
    font-size: 16px;
    line-height: 156%;
    margin: 0px 0 -5px 90px;
  }

  .timeline {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    .play-btn {
      color: #7a49a9;
      width: 42px;
      height: 42px;
      cursor: pointer;
    }
    .player {
      position: absolute;
      top: -99999px;
      display: none !important;
    }

    .time-label {
      font-size: 13px;
      padding: 0 10px;
    }

    .bar {
      width: calc(100% - 150px);
      height: 3px;
      position: relative;
      background-color: #888888;
      border-radius: 20px;
      cursor: pointer;
    }
    .control-bar {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: transparent;
      z-index: 2;
      border-radius: 20px;
    }
    .played-bar {
      width: ${(props) => (props.progress ? props.progress + "%" : "0%")};
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #333;
      z-index: 1;
      border-radius: 20px;
    }
  }

  @media (max-width: ${mobileMaxWidth}) {
    .title {
      margin: 0px 0 -5px 50px;
    }
  }
`
export { AudioPlayerWrapper, AudioPlayerControlPanel }
