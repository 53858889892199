import React from "react"
import {
  TestimonialSliderWrapper,
  TestimonialSliderImageRow,
  TestimonialSliderImageItem,
} from "./TestimonialSlider.style"

const TestimonialSlider = ({ data, time, imageWidth, imageMargin, fit }) => {
  return (
    <TestimonialSliderWrapper
      className="testimonial-slider"
      time={time}
      imgWidth={imageWidth}
      fit={fit}
      imageCount={data.length}
      imageMargin={imageMargin}
    >
      <TestimonialSliderImageRow className="gallery">
        {data.map((imageItem, index) => {
          return (
            <TestimonialSliderImageItem
              src={imageItem.url}
              key={`slider-image-1-${index}`}
            />
          )
        })}
        {data.map((imageItem, index) => {
          return (
            <TestimonialSliderImageItem
              src={imageItem.url}
              key={`slider-image-2-${index}`}
            />
          )
        })}
      </TestimonialSliderImageRow>
    </TestimonialSliderWrapper>
  )
}

export default TestimonialSlider
