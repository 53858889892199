import React from "react"
import { Helmet } from "react-helmet"

const PageTitle = ({ title }) => {
  return (
    <Helmet>
      {(window.pageTitle = title)}
      <title>{`${window.title} - ${window.pageTitle}`}</title>
    </Helmet>
  )
}

export default PageTitle
