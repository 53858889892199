import React from "react";
import { BodyContentContainerWrapper } from "./BodyContentContainer.style";


const CenterContainer = ({ className, children }) => {
  return (
    <BodyContentContainerWrapper
      data-testid="body-content-container"
      className={className}
    >
      {children}
    </BodyContentContainerWrapper>
  );
};

export default CenterContainer;
