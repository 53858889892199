const questions = [
  {
    title: "What is Innercise®?",
    answer: `<div>
              <span style="background-color: #ffffff; color: #000000">Innercise</span
              ><span style="background-color: transparent; color: #000000">®</span
              ><span style="background-color: #ffffff; color: #000000">
                is the&nbsp;<strong
                  >#1 mindset coaching and mental fitness training app</strong
                >. Over 100,000 success stories️ 
                <img
                  src="https://lh6.googleusercontent.com/AmpQq9mishwIO8WYe_vk9tZrCwNGO814ak17__yv52BO522LMB4Cs8-VLv33Z58mZKRJcbeiJHPNzlczfUGqSiUnoocuQP3n4remBuBv4ugtwpDFPPmGopc8rnhAuAVW156KU_Ennn0hgVErztgxKOtoonlIuHWq8jX4hG-mHpRCV1lO-AWe-xfButfLaw"
                  alt="⭐"
                  style="width:18px"
                />
                <img
                  src="https://lh6.googleusercontent.com/AmpQq9mishwIO8WYe_vk9tZrCwNGO814ak17__yv52BO522LMB4Cs8-VLv33Z58mZKRJcbeiJHPNzlczfUGqSiUnoocuQP3n4remBuBv4ugtwpDFPPmGopc8rnhAuAVW156KU_Ennn0hgVErztgxKOtoonlIuHWq8jX4hG-mHpRCV1lO-AWe-xfButfLaw"
                  alt="⭐"
                  style="width:18px"
                />
                <img
                  src="https://lh6.googleusercontent.com/AmpQq9mishwIO8WYe_vk9tZrCwNGO814ak17__yv52BO522LMB4Cs8-VLv33Z58mZKRJcbeiJHPNzlczfUGqSiUnoocuQP3n4remBuBv4ugtwpDFPPmGopc8rnhAuAVW156KU_Ennn0hgVErztgxKOtoonlIuHWq8jX4hG-mHpRCV1lO-AWe-xfButfLaw"
                  alt="⭐"
                  style="width:18px"
                />
                <img
                  src="https://lh6.googleusercontent.com/AmpQq9mishwIO8WYe_vk9tZrCwNGO814ak17__yv52BO522LMB4Cs8-VLv33Z58mZKRJcbeiJHPNzlczfUGqSiUnoocuQP3n4remBuBv4ugtwpDFPPmGopc8rnhAuAVW156KU_Ennn0hgVErztgxKOtoonlIuHWq8jX4hG-mHpRCV1lO-AWe-xfButfLaw"
                  alt="⭐"
                  style="width:18px"
                />
            </div>
            <div style="margin-top: 30px">
                <span style="background-color: #ffffff; color: #000000"
                >Start your free trial today to:&nbsp;</span>
            </div>
            <div style="padding: 30px 0; padding-left:20px">
            <div>-   Achieve any goal faster</div>
            <div>-   Boost self-confidence</div>
            <div>-   Release stress</div>
            <div>-   Stop procrastinating</div>
            <div>-   Eliminate limiting beliefs</div>
            <div>-   Live life on your own terms!</div>
            </div>
            <div>
                <span style="background-color: #ffffff; color: #000000"
                >Backed by&nbsp;<strong>cutting-edge science</strong> and 10 years
                of&nbsp;<strong>in-depth brain research.&nbsp;</strong></span
                >
            </div>
            `,
  },
  {
    title: "How fast will I see results?",
    answer: `<div>
      Most of our members experience&nbsp;<strong
        >more clarity, more confidence, and more certainty</strong
      >
      after&nbsp;<i>just 1 day</i> of Innercise®. Members also report
      having&nbsp;<strong
        >more emotional control, less stress, and less overwhelm</strong
      >
      during the first 3-7 days of using the app. We also have over 100,000 members
      who’ve used these science-based Innercises® to achieve their goals faster than
      ever before. So you could say the benefits are&nbsp;<u>immediate</u>, as well
      as&nbsp;<u>long-lasting</u>.
    </div>
    `,
  },
  // {
  //   title: "How do I invite friends to join Innercise®?",
  //   answer: `<div>
  //     Just share this page via&nbsp;<strong>email, text, or on social media</strong
  //     >. And remind your friends and family that they get to try the app for FREE!
  //   </div>
  //   `,
  // },
  {
    title: "How is Innercise® different?",
    answer: `<div>
        Innercise® uses the&nbsp;<strong
        >most advanced methods to reprogram and condition your subconscious
        mind&nbsp;</strong
      >to help you achieve ANY goal faster. Our methods have been verified and
      recommended by brain experts from&nbsp;<i>Harvard, Oxford,&nbsp;</i>and<i>
        Pepperdine</i
      >, and top psychologists and mental health experts from around the
      world.&nbsp;
    </div>
    <div style="padding-top: 20px">
      Just like exercise strengthens your muscles, Innercise is scientifically
      proven to strengthen your mind and optimize your brain so you&nbsp;<strong
        >perform better, think faster, and feel empowered to overcome any
        challenge</strong
      >
      life throws your way.&nbsp;
    </div>
    <div style="padding-top: 20px">
      Start your free trial today and experience why over 100,000 members across the
      globe are using this app to finally unlock their fullest power and
      potential.&nbsp;
    </div>
    `,
  },
  {
    title: "Who is John Assaraf?",
    answer: `<div>
        John Assaraf –&nbsp;<strong>"The Brain Whisperer"&nbsp;</strong>– is the
        founder of Innercise®. He is widely regarded as one of the leading<strong>
            mindset coaches and mental fitness training experts in the world</strong
        >
        after coaching and guiding over 100,000 people to life-changing
        success.&nbsp;&nbsp
        </div>
        <div style="padding-top: 20px">
        He has appeared as a guest on&nbsp;<i
            >Larry King, Anderson Cooper, Ellen, Ed Mylett,&nbsp;</i
        >and<i> Tom Bilyeu;</i> as well as a featured expert in the blockbuster hit
        movies “The Secret” and “Quest For Success” with Richard Branson and the Dalai
        Lama.
        </div>
        <div style="padding-top: 20px">
        Today, he is CEO of NeuroGym, a neuroscience-based technology company
        dedicated to using cutting-edge science and his years of research to help
        individuals&nbsp;<strong
            >achieve their goals and dreams… faster and easier than ever before.</strong
        >
        </div>
        `,
  },
  {
    title: "How does this app help improve my mindset?",
    answer: `<div>
              As the #1 mindset coaching and mental fitness training app in the world,
              Innercise® is scientifically proven to reprogram and condition your
              subconscious mind&nbsp;<strong
                >so you perform better, think faster, feel empowered, and take inspired
                action to overcome any challenge life throws your way.&nbsp;</strong
              >
            </div>
            <div style="padding-top: 20px">
              With over 500+ fully guided&nbsp;<strong
                >visualizations, meditations, affirmations, and high-performance brain
                training sessions</strong
              >, our app has everything you need to boost&nbsp;<i>confidence</i>,
              release&nbsp;<i>stress</i>, and eliminate the<i> limiting beliefs</i>
              and&nbsp;<i>fears</i> that hold you back from reaching your fullest power
              &amp; potential – backed by the latest scientific breakthroughs.&nbsp;
            </div>
            `,
  },
  {
    title: "Can anyone become an Innercise® member?",
    answer: `<div>
              Yes! We have over 100,000 Innercise® success stories.&nbsp;<strong
                >We want YOU to be next</strong
              >. Just download the app today and start achieving any goal… faster and easier
              than ever before.
            </div>`,
  },
  {
    title: "How much does it cost?",
    answer: `<div>
              For a limited time, we are offering a&nbsp;<strong
                >FREE trial for the Innercise® app</strong
              >. Just download the app from the App Store today and get unlimited access to
              over 500+ new Innercises (for&nbsp;<strong>$0</strong>).&nbsp;
            </div>`,
  },
]

export { questions }
