import styled from "@emotion/styled";

const StyledCategoryMenu = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid #d1d7dc;
  background: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
  font-size: 16px;
  margin-bottom: 1px;
  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin-right: 10px;
      padding: 5px;
      width: 100px;
      button {
        height: 50px;
        min-width: 100px;
        width: fit-content;
        background-color: transparent;
        border: 0;
        color: #1d1d1f;
        cursor: pointer;
        font-size: 16px;
      }
    }
  }e
`;

export { StyledCategoryMenu };
