import React, { useEffect, useRef, useState } from "react"
import PageTitle from "components/common/PageTitle/PageTitle"
import QrCode from "components/common/QrCode"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import DownloadAppSendLinkDlg from "./DownloadAppSendLinkDlg"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { DownloadAppSharePageWrapper } from "./DownloadAppSharePage.style"

const DownloadAppSharePage = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const email = queryParams.get("email") || ""
  const name = queryParams.get("name") || ""

  const [isSent, setIsSent] = useState(false)
  const [sentEmail, setSentEmail] = useState("")

  const formEmailRef = useRef()
  const formTextRef = useRef()

  const initMarketo = () => {
    window.MktoForms2.loadForm(
      "//join.myneurogym.com",
      "519-VOW-812",
      1143,
      (form) => {
        form.onSuccess(function (values, followUpUrl) {
          // On Success
          console.log("Success Submit Marketo")
          form.getFormElem().hide()

          setIsSent(true)

          // Redirect after processing on your side to confirmation page

          return false
        })
        formEmailRef.current = form
      }
    )

    window.MktoForms2.loadForm(
      "//join.myneurogym.com",
      "519-VOW-812",
      1144,
      (form) => {
        form.onSuccess(function (values, followUpUrl) {
          // On Success
          console.log("Success Submit Marketo")
          form.getFormElem().hide()
          // Redirect after processing on your side to confirmation page

          return false
        })
        formTextRef.current = form
      }
    )
  }

  const submitEmailForm = (email) => {
    // If Marketo Form is Ready, then submit Marketo Form
    formEmailRef.current.setValues({
      Email: email,
    })
    setSentEmail(email)
    formEmailRef.current.submit()
  }

  const submitTextForm = (phone) => {
    // If Marketo Form is Ready, then submit Marketo Form
    formTextRef.current.setValues({
      Phone: phone,
    })
    formTextRef.current.submit()
  }

  const [openSendLinkDlg, sendLinkDlg, sendLinkloading] =
    DownloadAppSendLinkDlg({
      emailAddress: email,
      submitEmailForm,
      submitTextForm,
    })

  useEffect(() => {
    setIsSent(false)
    initMarketo()
  }, [])

  return (
    <DownloadAppSharePageWrapper>
      <PageTitle title="Download App Share Page" />
      <div className="logo">
        <a href="/download">
          <img src="/images/i-logo.png" alt="logo" />
        </a>
      </div>
      <div className="content">
        {isSent ? (
          <>
            <div className="nav-back">
              <a href="/download">
                <KeyboardBackspaceIcon /> Back
              </a>
            </div>
            <div className="link-sent-title">
              <CheckCircleOutlineIcon />
              The Link Was Sent To
            </div>
            <div className="email">{sentEmail}</div>

            <button
              className="action-link"
              onClick={() => {
                setIsSent(false)
                openSendLinkDlg()
              }}
            >
              not you?
            </button>
          </>
        ) : (
          <>
            <div className="welcome">
              Welcome To innercise®{name !== "" && `, ${name}!`}
            </div>
            <div className="heading">Download The innercise® App</div>
            <div className="sub-heading">
              To download this app, just open your phone's camera app to scan
              this QR code
            </div>
            <QrCode
              codeValue="https://apps.apple.com/us/app/innercise/id1616604572"
              width="160"
              height="160"
            />
            <a href="https://apps.apple.com/us/app/innercise/id1616604572">
              <img
                src="/images/app-store.png"
                alt="app store"
                className="app-store"
              />
            </a>
            <button
              className="action-link"
              onClick={() => {
                openSendLinkDlg()
              }}
            >
              email / text me the link
            </button>
          </>
        )}
      </div>

      {sendLinkDlg}
    </DownloadAppSharePageWrapper>
  )
}

export default DownloadAppSharePage
