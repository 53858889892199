import { Auth0Provider } from "@auth0/auth0-react";
import Routes from "components/Routes";
import "./App.scss";

function App() {
  console.log(process.env);

  return (
    <Auth0Provider>
      <div className="App">
        <Routes />
      </div>
    </Auth0Provider>
  );
}

export default App;
