import styled from "styled-components"

const VideoPlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;

  .player {
    video {
      border-radius: 5px;
      -webkit-box-shadow: 0px 10px 46px -26px rgba(0, 0, 0, 1);
      -moz-box-shadow: 0px 10px 46px -26px rgba(0, 0, 0, 1);
      box-shadow: 0px 10px 46px -26px rgba(0, 0, 0, 1);
    }
  }

  .over-layer {
    position: absolute;
    display: flex;
    border-radius: 5px;
    background-color: rgb(0 0 0 / 25%);
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 5px);
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.35s ease;
    svg {
      width: 88px;
      height: 88px;
      cursor: pointer;
    }
    &.show {
      opacity: 1;
      transition: opacity 0.5s ease;
    }
    .thumb-img {
      width: 100.05%;
      height: 100.3%;
      border-radius: 5px;
      left: 0;
      top: 0;
      position: absolute;
      z-index: 0;
    }
    .MuiSvgIcon-root {
      z-index: 2;
    }
  }
`

export { VideoPlayerWrapper }
